import React, {useEffect, useState} from "react";
import {useRequest, useValidation} from "hooks";
import {adminSTMPrequest} from "requests";
import {SingleSelect, SpinnerButton, ValidatedInput} from "utils";
import {stmpFormValidations} from "./validations";
import {toast} from "react-toastify";

const defaultSettings = {
    host: "",
    username: "",
    password: "",
    port: "",
    auth_type: "",
    sender_address: "",
    sender_name: "",
};

const EditSTMPForm = () => {
    const [settings, setSettings] = useState(defaultSettings);
    const {errors, validate} = useValidation(stmpFormValidations);
    const [request, isRequesting] = useRequest(adminSTMPrequest);

    const options = [
        {name: "SSL", value: "SSL"},
        {name: "TLS", value: "TLS"},
        {name: "none", value: "NONE"},
    ];
    useEffect(() => {
        request({type: "GET"})
            .then((r) => {
                setSettings(r.data.data);
            })
            .catch((e) => {});
    }, []);
    const onChangeHandler = ({target: {name, value}}) => {
        const newSettings = {...settings, [name]: value};
        setSettings(newSettings);
        validate(newSettings, name).catch((e) => {});
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        validate(settings)
            .then((r) => {
                request(settings)
                    .then((r) => {
                        toast.success("STMP credentials saved successfully");
                    })
                    .catch((e) => {
                        toast.error("Saving failed");
                    });
            })
            .catch((e) => {});
    };
    const callback = (currency, i) => {
        return (
            <option value={currency.value} key={i}>
                {currency.name}
            </option>
        );
    };
    return (
        <form className="user" onSubmit={onSubmitHandler}>
            <div className="form-group">
                <div className="form-row">
                    <div className="form-col col-12 col-md-6 mb-3 mb-md-0">
                        <ValidatedInput
                            type="text"
                            name="host"
                            className="form-control bg-light"
                            placeholder="SMTP host"
                            label="SMTP host"
                            value={settings.host}
                            onChange={onChangeHandler}
                            error={errors.host}
                        />
                    </div>
                    <div className="form-col col-12 col-md-6">
                        <ValidatedInput
                            type="text"
                            name="password"
                            className="form-control bg-light"
                            placeholder="SMTP password"
                            label="SMTP password"
                            value={settings.password}
                            error={errors.password}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="form-row">
                    <div className="form-col col-12 col-md-4 mb-3 mb-md-0">
                        <ValidatedInput
                            type="text"
                            name="username"
                            className="form-control bg-light"
                            placeholder="SMTP username"
                            label="SMTP username"
                            value={settings.username}
                            error={errors.username}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="form-col col-12 col-md-4 mb-3 mb-md-0">
                        <ValidatedInput
                            type="text"
                            name="port"
                            className="form-control bg-light"
                            placeholder="SMTP port"
                            label="SMTP port"
                            value={settings.port}
                            error={errors.port}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="form-col col-12 col-md-4">
                        <SingleSelect
                            type="text"
                            name="auth_type"
                            className="form-control bg-light"
                            placeholder="SMTP encryption type"
                            label="SMTP encryption type"
                            value={settings.auth_type}
                            error={errors.auth_type}
                            onChange={onChangeHandler}
                            options={options}
                            callback={callback}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="form-row">
                    <div className="form-col col-12 col-md-6">
                        <ValidatedInput
                            type="text"
                            name="sender_name"
                            className="form-control bg-light"
                            placeholder="Sender name"
                            label="Sender name"
                            value={settings.sender_name}
                            error={errors.sender_name}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="form-col col-12 col-md-6 mb-3 mb-md-0">
                        <ValidatedInput
                            type="text"
                            name="sender_address"
                            className="form-control bg-light"
                            placeholder="Sender email"
                            label="Sender email"
                            value={settings.sender_address}
                            error={errors.sender_address}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
            </div>

            <SpinnerButton
                className="btn btn-primary btn-block"
                loading={isRequesting}
                type="submit"
            >
                Save
            </SpinnerButton>
        </form>
    );
};
export default EditSTMPForm;
