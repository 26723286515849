import vest, {validate, test, enforce} from "vest";

export default function registerFormValidations(data, field, formatMessage) {
    return validate("RegisterForm", () => {
        vest.only(field);
        [
            "company",
            "address_line_1",
            "country_code",
            "postal_code",
        ].forEach((elem) => {
            test(
                elem,
                formatMessage({
                    id: "required_field",
                    defaultMessage: "This field is required",
                }),
                () => {
                    enforce(data[elem].toString()).isNotEmpty();
                }
            );
        });

        test(
            "terms_and_policy",
            formatMessage({
                id: "accept_terms_required",
                defaultMessage:
                    "Please accept Privacy Policy & Terms of Service",
            }),
            () => {
                const r =
                    data.terms_and_policy === null || data.terms_and_policy;
                enforce(r).isTruthy();
            }
        );
    });
}
