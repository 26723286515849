import vest, {validate, test, enforce} from "vest";

export default function subscriptionFormValidations(
    data,
    field,
    formatMessage
) {
    return validate("SubscriptionPlanForm", () => {
        vest.only(field);
        ["username", "password", "privileges"].forEach((elem) => {
            test(elem, "This field is required", () => {
                enforce(data[elem].toString()).isNotEmpty();
            });
        });
        test(
            "username",
            "Username should be at most 12 characters long",
            () => {
                enforce(data.username.length).lessThanOrEquals(12);
            }
        );
        if (data.password)
            test(
                "password",
                "Password should be at least 8 characters long",
                () => {
                    enforce(data.password.toString()).longerThanOrEquals(8);
                }
            );
    });
}
