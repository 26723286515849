import React from "react";
import {Modal} from "utils";
import {FilterTransactionsForm} from "components/forms";

const FilterTransactionsModel = ({setTransactions, transactions}) => {
    return (
        <>
            <Modal id="filter-transaction" header="Filter Transactions" multiSelect>
                <FilterTransactionsForm
                    setTransactions={setTransactions}
                    transactions={transactions}
                />
            </Modal>
        </>
    );
};

export default FilterTransactionsModel;
