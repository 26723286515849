import {AdminLogsTable} from "components/tables";
import React from "react";

const AdminLogsPage = () => {
    return (
        <>
            <div className="container-fluid">
                {/* <h1 className="h3 mb-2 text-gray-800">Transactions</h1> */}

                <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h6 className="m-0 font-weight-bold text-primary">
                            Logs
                        </h6>
                        {/* <div className="ml-auto d-flex">
                            <div className="mr-2">
                                <AddTransactionsModel />
                            </div>
                            <FilterTransactionsModel
                                setTransactions={setTransactions}
                            />
                        </div> */}
                    </div>
                    <div className="card-body">
                        <AdminLogsTable />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminLogsPage;
