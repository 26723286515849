import {ForgotPasswordForm, FormWrapper} from "components/forms";
import React from "react";

const ForgotPasswordPage = () => {
    return (
        <FormWrapper>
            <ForgotPasswordForm />
        </FormWrapper>
    );
};

export default ForgotPasswordPage;
