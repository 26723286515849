import React, {useEffect, useState} from "react";
import {useRequest, useValidation} from "hooks";
import {adminSubscriptionRequests, planOptions} from "requests";
import {SingleSelect, currencies, SpinnerButton, ValidatedInput} from "utils";
import {subscriptionFormValidations} from "./validations";
import {BsPlusCircle} from "react-icons/bs";
import {IoIosRemoveCircleOutline} from "react-icons/io";
import {toast} from "react-toastify";
import $ from "jquery";

const defaultPlan = {
    // title: "",
    // price_per_month: "",
    // price_currency: "",
    maximum_number_of_devices: "",
    storage_megabytes: "",
};
const defaultPrice = {
    subscription_length_days: 30,
    price_amount: 10,
    price_currency: "EUR",
};

const SubscriptionPlanForm = ({edit, plan: props_plan, triggerFetch}) => {
    const [plan, setPlan] = useState(defaultPlan);
    const [price, setPrice] = useState(defaultPrice);
    const [prices, setPrices] = useState([]);
    const {errors, validate} = useValidation(subscriptionFormValidations);
    const [request, isRequesting] = useRequest(adminSubscriptionRequests);
    const [optionsRequest, isRequestingOptions] = useRequest(planOptions);

    useEffect(() => {
        if (props_plan && props_plan.plan_id && edit) {
            // console.log(props_plan);
            setPlan(props_plan);
            optionsRequest({id: props_plan.plan_id})
                .then((r) => {
                    setPrices(r.data.data.plan.pricing);
                })
                .catch((e) => {
                    setPrices([]);
                });
        }
    }, [props_plan]);
    const onChangeHandler = ({target: {name, value}}) => {
        const newPlan = {...plan, [name]: value};
        setPlan(newPlan);
        validate(newPlan, name).catch((e) => {});
    };
    const onPriceChangeHandler = ({target: {name, value}}) => {
        const newPrice = {...price, [name]: value};
        setPrice(newPrice);
        // validate(newPrice, name).catch((e) => {});
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        const type = edit ? "EDIT" : "ADD";
        validate(plan)
            .then((r) => {
                request({...plan, type})
                    .then((r) => {
                        if (prices && prices.length > 0) {
                            let plan_id = props_plan ? props_plan.plan_id : {};
                            if (!edit) plan_id = r.data.data.plan.plan_id;
                            const payload = {
                                plan_id: plan_id,
                                pricing: prices,
                                type: "EDIT",
                            };
                            optionsRequest(payload)
                                .then((r) => {
                                    toast.success(
                                        "Saved plan with prices successfully"
                                    );
                                    triggerFetch(Math.random());
                                    if (edit)
                                        $("#edit-subscription-plan").modal(
                                            "hide"
                                        );
                                    else
                                        $("#add-subscription-plan").modal(
                                            "hide"
                                        );
                                    $("body").removeClass("modal-open");
                                    $(".modal-backdrop").remove();
                                })
                                .catch((e) => {
                                    toast.error(
                                        "Error occured while saving prices"
                                    );
                                });
                        } else {
                            toast.success("Saved plan successfully");
                            triggerFetch(Math.random());
                            if (edit)
                                $("#edit-subscription-plan").modal("hide");
                            else $("#add-subscription-plan").modal("hide");
                            $("body").removeClass("modal-open");
                            $(".modal-backdrop").remove();
                        }
                    })
                    .catch((e) => {
                        toast.error("Error occured while saving plan");
                    });
            })
            .catch((e) => {});
    };
    const callback = (currency, i) => {
        return (
            <option value={currency.value} key={i}>
                {currency.label}
            </option>
        );
    };
    const addPrice = () => {
        const newPrices = prices.slice();
        newPrices.push(price);
        setPrices(newPrices);
        setPrice(defaultPrice);
    };
    const removePrice = (index) => {
        const newPrices = prices.slice();
        newPrices.splice(index, 1);
        setPrices(newPrices);
    };
    return (
        <form className="user" onSubmit={onSubmitHandler}>
            <div className="form-group">
                <ValidatedInput
                    type="text"
                    name="title"
                    className="form-control bg-light"
                    placeholder="Title"
                    label="Title"
                    value={plan.title}
                    onChange={onChangeHandler}
                    error={errors.title}
                />
            </div>
            <div className="form-group">
                <ValidatedInput
                    type="text"
                    name="maximum_number_of_devices"
                    className="form-control bg-light"
                    placeholder="Number of devices"
                    label="Number of devices"
                    value={plan.maximum_number_of_devices}
                    error={errors.maximum_number_of_devices}
                    onChange={onChangeHandler}
                />
            </div>
            <div className="form-group">
                <ValidatedInput
                    type="text"
                    name="storage_megabytes"
                    className="form-control bg-light"
                    placeholder="Maximum available storage"
                    label="Maximum available storage"
                    value={plan.storage_megabytes}
                    error={errors.storage_megabytes}
                    onChange={onChangeHandler}
                />
            </div>
            <div className="form-group">
                <div className="form-row">
                    <div className="form-col col-12 col-md-4 mb-3 mb-md-0">
                        <ValidatedInput
                            type="text"
                            name="subscription_length_days"
                            className="form-control bg-light"
                            placeholder="Length in days"
                            label="Length in days"
                            value={price.subscription_length_days}
                            // error={errors.subscription_length_days}
                            onChange={onPriceChangeHandler}
                        />
                    </div>
                    <div className="form-col col-6 col-md-3">
                        <ValidatedInput
                            type="text"
                            name="price_amount"
                            className="form-control bg-light"
                            placeholder="Price"
                            label="Price"
                            value={price.price_amount}
                            // error={errors.price_amount}
                            onChange={onPriceChangeHandler}
                        />
                    </div>
                    <div className="form-col col-5 col-md-3">
                        <SingleSelect
                            type="text"
                            name="price_currency"
                            className="form-control bg-light"
                            placeholder="Currency"
                            label="Currency"
                            value={price.price_currency}
                            // error={errors.price_currency}
                            onChange={onPriceChangeHandler}
                            options={currencies}
                            callback={callback}
                        />
                    </div>
                    <div className="form-col col-1 col-md-2">
                        <div
                            style={{
                                display: "flex",
                                alignItems: "flex-end",
                                height: "100%",
                                width: "100%",
                            }}
                        >
                            <SpinnerButton
                                className="btn btn-outline-primary w-100"
                                disabled={isRequesting}
                                type="button"
                                onClick={addPrice}
                            >
                                <BsPlusCircle />
                            </SpinnerButton>
                        </div>
                    </div>
                </div>
                <div className="form-row p-2">
                    {isRequestingOptions ? (
                        <div className="d-flex justify-content-center pt-4 w-100 align-items-center">
                            <div
                                className="spinner-border  text-primary"
                                role="status"
                            ></div>
                        </div>
                    ) : (
                        <ul className="list-group w-100">
                            {prices.map((p, i) => (
                                <li
                                    className="list-group-item d-flex justify-content-between"
                                    key={i}
                                >
                                    {p.subscription_length_days +
                                        " days for " +
                                        p.price_amount +
                                        " " +
                                        p.price_currency}
                                    <a
                                        href="/"
                                        className="badge badge-danger badge-pill d-flex align-items-center"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            removePrice(i);
                                        }}
                                    >
                                        <IoIosRemoveCircleOutline />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
            <div className="form-group py-2">
                <SpinnerButton
                    className="btn btn-primary btn-block"
                    loading={isRequesting}
                    type="submit"
                >
                    Save
                </SpinnerButton>
            </div>
        </form>
    );
};
export default SubscriptionPlanForm;
