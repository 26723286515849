import vest, {validate, test, enforce} from "vest";

export default function filterTransFormValidations(data, field, formatMessage) {
    return validate("SubscriptionPlanForm", () => {
        vest.only(field);

        test("to", "Start date must be before end date", () => {
            if (data.from && data.to) enforce(data.to >= data.from).isTruthy();
        });
        test("from", "Start date must be before end date", () => {
            if (data.from && data.to) enforce(data.from >= data.to).isTruthy();
        });
    });
}
