import {privileges as p, SUPER_ADMIN} from "config/privilege";
import {AuthContext} from "context";
import {useContext} from "react";

const usePrivilege = () => {
    const {auth} = useContext(AuthContext);
    const canAccess = (privilege) => {
        if (auth.account_type === "ADMIN") {
            if (auth.privileges.includes(SUPER_ADMIN)) return true;
            return auth.privileges.includes(privilege);
        }
    };
    return {canAccess};
};

export default usePrivilege;
