import React from "react";
import {Modal} from "utils";
import {EditClientForm} from "components/forms";

const EditClientModal = ({client, triggerFetch}) => {
    return (
        <>
            <Modal
                id="edit-registered-client"
                header="Edit client"
                size="md"
            >
                <div className="container-fluid pb-3">
                    <EditClientForm
                        client={client}
                        triggerFetch={triggerFetch}
                    />
                </div>
            </Modal>
        </>
    );
};

export default EditClientModal;
