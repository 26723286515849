import vest, {validate, test, enforce} from "vest";
import {truncate} from "utils";

export default function validations(data, field, formatMessage) {
    return validate("EditClientForm", () => {
        vest.only(field);
        [
            "username",
            "name",
            "company",
            "email",
            "country_code",
            "address_line_1",
            "postal_code",
            "country_code",
        ].forEach((elem) => {
            test(elem, "This field is required", () => {
                enforce(data[elem].toString()).isNotEmpty();
            });
        });

        test(
            "username",
            "Username should be at most 12 characters long",
            () => {
                enforce(data.username.length).lessThanOrEquals(12);
            }
        );

        test(
            "username",
            "Username must only contain lowercase letters, numbers and underscores",
            () => {
                enforce(data.username.toString())
                    .isNotEmpty()
                    .matches(/^[A-Za-z0-9_]*$/g);
            }
        );
        test(
            "username",
            "Username should be at least 5 characters long",
            () => {
                enforce(data.username.toString()).longerThanOrEquals(5);
            }
        );

        const trimmedEmail = data.email
            ? truncate(data.email.toString(), 15)
            : "";
        test(
            "email",
            trimmedEmail.concat(" " + "Email is not valid email address"),
            () => {
                enforce(data.email.toString())
                    .isNotEmpty()
                    .matches(/[^@]+@[^.]+\..+/g);
            }
        );
        if (data.password)
            test(
                "password",
                "Password should be at least 8 characters long",
                () => {
                    enforce(data.password.toString()).longerThanOrEquals(8);
                }
            );
        if (data.password_confirm)
            test("password_confirm", "Passwords should be matching", () => {
                enforce(
                    !data.password
                        .toString()
                        .localeCompare(data.password_confirm.toString())
                ).isTruthy();
            });
        if (data.password)
            test("password", "Passwords should be matching", () => {
                if (data.password_confirm && data.password_confirm !== "")
                    enforce(
                        !data.password
                            .toString()
                            .localeCompare(data.password_confirm.toString())
                    ).isTruthy();
            });
    });
}
