import React, {useState, useEffect} from "react";
import {
    FormattedDate,
    FormattedMessage,
    FormattedNumber,
    useIntl,
} from "react-intl";
import moment from "moment";
import $ from "jquery";

const DashboardCard = ({isRequesting, data: oldData = {}}) => {
    const {formatMessage} = useIntl();
    const [data, setData] = useState({});
    const labels = [
        formatMessage({id: "client_username", defaultMessage: "Username"}),
        formatMessage({id: "pointx_domain", defaultMessage: "PointX domain"}),
        // formatMessage({id: "sub_plan_id", defaultMessage: "Plan ID"}),
        formatMessage({id: "sub_plan_title", defaultMessage: "Plan title"}),
        formatMessage({id: "created_at", defaultMessage: "Created at"}),
        formatMessage({id: "renewal_date", defaultMessage: "Expiration Date"}),
        formatMessage({id: "max_devices", defaultMessage: "Maximum devices"}),
        formatMessage({id: "max_storage", defaultMessage: "Maximum storage"}),
        formatMessage({id: "timezone", defaultMessage: "Timezone"}),
    ];
    const keys = [
        "client_username",
        "pointx_domain",
        // "plan_id",
        "plan_title",
        "created_at",
        "renewal_date",
        "maximum_number_of_devices",
        "allocated_storage_megabytes",
        "timezone",
    ];
    useEffect(()=>{
        setTimeout(()=>{
            $("[data-toggle=tooltip]").tooltip();
        });
    }, [data]);
    useEffect(() => {
        const {
            id = "ID",
            plan_id = "PLAN_ID",
            pointx_domain = "PLAN_ID",
            plan_title = "PLAN_ID",
            allocated_storage_megabytes = 1024,
            created_at = new Date(),
            renewal_date = new Date(),
            maximum_number_of_devices = 10,
            client_username = "Username",
            timezone = "UTC",
            is_renewed_automatically = true,
        } = oldData;
        const days = moment(renewal_date).diff(moment(), "days");
        const parsedData = {
            id,
            client_username,
            plan_id,
            pointx_domain,
            plan_title,
            timezone,
            active: days >= 0,
            due: days < 0 && days >= -14,
            expired: days < -14 && days >= -45,
            automatic: is_renewed_automatically,
            renewal_date: moment(renewal_date).isAfter("2100-12-31", "year") ? ( // true
                <FormattedMessage id="never" defaultMessage="Never" />
            ) : (
                <FormattedDate
                    value={renewal_date}
                    year="numeric"
                    month="long"
                    day="2-digit"
                />
            ),
            allocated_storage_megabytes: (
                <FormattedNumber
                    value={(allocated_storage_megabytes / 1024).toFixed(2)}
                    style="unit"
                    unit="gigabyte"
                    unitDisplay="narrow"
                />
            ),
            maximum_number_of_devices: (
                <FormattedNumber
                    value={maximum_number_of_devices}
                    style="decimal"
                />
            ),
            created_at: (
                <FormattedDate
                    value={created_at}
                    year="numeric"
                    month="long"
                    day="2-digit"
                />
            ),
        };
        setData(parsedData);
    }, [oldData]);
    return isRequesting ? (
        <div className="d-flex justify-content-center h-100 align-items-center">
            <div className="spinner-border  text-primary" role="status"></div>
        </div>
    ) : (
        <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                    {formatMessage({id: "info", defaultMessage: "Info"})}
                </h6>
            </div>
            <div className="card-body">
                <dl>
                    <dt>
                        {formatMessage({
                            id: "status",
                            defaultMessage: "Status",
                        })}
                    </dt>
                    <dd className="d-flex">
                        <h5 className="mr-2">
                            <span
                                className={`badge text-white bg-${
                                    data.active
                                        ? "success"
                                        : data.due
                                        ? "warning"
                                        : data.expired
                                        ? "danger"
                                        : ""
                                }`}
                                data-toggle="tooltip"
                                title={
                                    data.active
                                        ? formatMessage({
                                              id: "active_tooltip",
                                              defaultMessage:
                                                  "Your subscription is active",
                                          })
                                        : data.due
                                        ? formatMessage({
                                              id: "due_tooltip",
                                              defaultMessage:
                                                  "Your subscription is active but will expire within 14 days of renewal date",
                                          })
                                        : data.expired
                                        ? formatMessage({
                                              id: "expired_tooltip",
                                              defaultMessage:
                                                  "Your subscription has Expired you cannot access your data anymore and your data will be deleted within 45 days of expiration date",
                                          })
                                        : ""
                                }
                                data-placement="top"
                            >
                                {data.active
                                    ? formatMessage({
                                          id: "active",
                                          defaultMessage: "Active",
                                      })
                                    : data.due
                                    ? formatMessage({
                                          id: "due",
                                          defaultMessage: "Payment Due",
                                      })
                                    : data.expired
                                    ? formatMessage({
                                          id: "expired",
                                          defaultMessage: "Expired",
                                      })
                                    : ""}
                            </span>
                        </h5>
                        {data.plan_id !== "FREE" &&
                            data.active &&
                            data.automatic && (
                                <h5>
                                    <span
                                        className={`badge text-white bg-${
                                            data.automatic
                                                ? "info"
                                                : "danger"
                                        }`}
                                        data-toggle="tooltip"
                                        title={
                                            data.automatic
                                                ? formatMessage({
                                                      id:
                                                          "is_automatic_tooltip",
                                                      defaultMessage:
                                                          "Auto-renewal enabled",
                                                  })
                                                : formatMessage({
                                                      id:
                                                          "is_not_automatic_tooltip",
                                                      defaultMessage:
                                                          "Auto-renewal disabled",
                                                  })
                                        }
                                        data-placement="top"
                                    >
                                        {formatMessage({
                                            id: "auto",
                                            defaultMessage: "Auto",
                                        })}
                                    </span>
                                </h5>
                            )}
                    </dd>
                    {labels.map((label, i) => (
                        <React.Fragment key={i}>
                            <dt>{label}</dt>
                            <dd>{data[keys[i]]}</dd>
                        </React.Fragment>
                    ))}
                </dl>
            </div>
        </div>
    );
};
export default DashboardCard;
