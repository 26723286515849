import React, {useState, useEffect} from "react";
import {Modal, SpinnerButton} from "utils";
import {useRequest, useStripe} from "hooks";
import {clientSubscribeToPlan, planOptions} from "requests";
import {FormattedMessage, FormattedNumber, useIntl} from "react-intl";
import {loadStripe} from "@stripe/stripe-js";
import {GiCheckMark} from "react-icons/gi";
import {dialog} from "bootbox";
import $ from "jquery";

const SubscriptionModal = ({plan}) => {
    const [selected, setSelected] = useState(null);
    const [options, setOptions] = useState([]);
    const [request, isRequesting] = useRequest(clientSubscribeToPlan);
    const [optionsRequest, isRequestingOptions] = useRequest(planOptions);
    const {formatMessage} = useIntl();
    const [stripeKey] = useStripe();

    useEffect(() => {
        setOptions([]);
        setSelected(null);
        if (plan)
            optionsRequest({id: plan.plan_id})
                .then((r) => {
                    setOptions(r.data.data.plan.pricing);
                })
                .catch((e) => {});
    }, [plan]);

    const onClick = async (automatic_renewal) => {
        const stripe = await loadStripe(stripeKey);
        request({
            plan_id: plan.plan_id,
            automatic_renewal: automatic_renewal,
            subscription_id: plan.subscription_id,
            subscription_length_days:
                options[selected].subscription_length_days,
        })
            .then((r) => {
                const stripe_session_id = r.data.data.stripe_session_id;
                stripe
                    .redirectToCheckout({
                        sessionId: stripe_session_id,
                    })
                    .then((r) => {
                        console.log(r);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            })
            .catch(() => {});
    };
    const confirmClick = () => {
        $("#subscribe").modal("hide");
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
        dialog({
            message: formatMessage({
                id: "renewal_inquiry",
                defaultMessage: "Please select your renewal option",
            }),
            size: "large",
            centerVertical: true,
            buttons: {
                cancel: {
                    label: formatMessage({
                        id: "cancel",
                        defaultMessage: "Cancel",
                    }),
                    className: "btn-primary",
                    callback: () => true,
                },
                second: {
                    label: formatMessage({
                        id: "continue_without_renew",
                        defaultMessage: "Subscribe without auto renewal",
                    }),
                    className: "btn-outline-danger",
                    callback: () => onClick(false),
                },
                first: {
                    label: formatMessage({
                        id: "continue_with_renew",
                        defaultMessage: "Subscribe with auto renewal",
                    }),
                    className: "btn-outline-success",
                    callback: () => onClick(true),
                },
            },
        });
    };
    return (
        <Modal
            id="subscribe"
            header={
                <FormattedMessage id="subscribe" defaultMessage="Subscribe" />
            }
        >
            {isRequestingOptions ? (
                <div className="d-flex justify-content-center">
                    <div
                        className="spinner-border  text-primary"
                        role="status"
                    ></div>
                </div>
            ) : (
                <div>
                    <ul className="list-group">
                        {options.map((price, i) => (
                            <li
                                className={`list-group-item  ${
                                    selected === i ? "active" : ""
                                }`}
                                key={i}
                                onClick={() => setSelected(i)}
                            >
                                {selected === i && (
                                    <GiCheckMark className="mr-1" />
                                )}
                                <FormattedMessage
                                    id="plan_duration_months"
                                    defaultMessage="{duration} days for "
                                    values={{
                                        duration:
                                            price.subscription_length_days,
                                    }}
                                    style="unit"
                                    unit="time"
                                />{" "}
                                <FormattedNumber
                                    value={price.price_amount}
                                    style="currency"
                                    currency={price.price_currency}
                                />
                            </li>
                        ))}
                    </ul>
                    <SpinnerButton
                        className="btn btn-outline-primary w-100 mt-3"
                        loading={isRequesting}
                        disabled={selected === null}
                        onClick={confirmClick}
                    >
                        <FormattedMessage
                            id="proceed_to_payment"
                            defaultMessage="Proceed to payment"
                        />
                    </SpinnerButton>
                </div>
            )}
        </Modal>
    );
};
export default SubscriptionModal;
