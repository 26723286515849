import React, {useEffect, useState} from "react";
import {useRequest, useValidation} from "hooks";
import {
    adminClientRequests,
    planOptions,
    clientSubscriptionsRequest as getPlans,
} from "requests";
import {SingleSelect, SpinnerButton} from "utils";
import {editClientFormValidations} from "./validations";
import {toast} from "react-toastify";
import $ from "jquery";

const defaultClientTransaction = {
    client_username: "",
    subscription_length_days: "",
    plan_id: "",
};

const EditClientTransactionForm = ({client}) => {
    const [transaction, setTransaction] = useState(defaultClientTransaction);
    const [sub, setSub] = useState({});
    const {errors, validate} = useValidation(editClientFormValidations);
    const [request, isRequesting] = useRequest(adminClientRequests);
    const [optionsRequest, isRequestingOptions] = useRequest(planOptions);
    const [options, setOptions] = useState([]);
    const [plans, setPlans] = useState([]);
    const [isRenewing, setRenew] = useState(true);
    const [planRequest, isRequestingPlans] = useRequest(getPlans);

    useEffect(() => {
        planRequest()
            .then(({data}) => {
                const plans = data.data.plans;
                setPlans(plans.filter((plan) => plan.plan_id !== "FREE"));
            })
            .catch((e) => {});
    }, []);

    const getOptions = (id) => {
        optionsRequest({id: id})
            .then((r) => {
                setOptions(r.data.data.plan.pricing);
            })
            .catch((e) => {});
    };

    useEffect(() => {
        setTransaction(defaultClientTransaction);
        if (client && client.name) {
            request({type: "SUB", client_username: client.username})
                .then((r) => {
                    const s = r.data.data.subscriptions[0];
                    setTransaction({
                        ...defaultClientTransaction,
                        client_username: client.username,
                        plan_id: s.plan_id,
                    });
                    setSub(s);
                    if (s.plan_id !== "FREE") getOptions(s.plan_id);
                })
                .catch((e) => {});
        }
    }, [client]);

    const onChangeHandler = ({target: {name, value}}) => {
        const newTransacton = {...transaction, [name]: value};
        setTransaction(newTransacton);
        validate(newTransacton, name).catch((e) => {});
    };
    const onSelect = ({target: {name, value}}) => {
        setRenew(value === sub.plan_id);
        let newTransaction = {...transaction, [name]: value};
        if (value === "FREE") setOptions([]);
        else getOptions(value);
        newTransaction.subscription_length_days = "";
        setTransaction(newTransaction);
        validate(newTransaction, name).catch((e) => {});
    };
    const hideModal = () => {
        $("#edit-client-subscription").modal("hide");
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
    };
    const onSubmitHandler = (e) => {
        e.preventDefault();
        if (isRenewing) renew();
        else pay();
    };
    const callback = (item, i) => {
        return (
            <option value={item.plan_id} key={i}>
                {item.title}
            </option>
        );
    };
    const optionsCallback = (price, i) => {
        return (
            <option value={price.subscription_length_days} key={i}>
                {price.subscription_length_days +
                    " for " +
                    price.price_amount +
                    " " +
                    price.price_currency}
            </option>
        );
    };
    const renew = () => {
        let t = {
            subscription_id: sub.id, // Send only if renewal
            plan_id: sub.plan_id, // Send only if renewal
            subscription_length_days: transaction.subscription_length_days,
            client_username: transaction.client_username,
        };
        request({...t, type: "RENEW"})
            .then((r) => {
                toast.success("Renewed successfully");
                hideModal();
            })
            .catch((e) => {
                toast.error("Error occured while renewing");
            });
    };
    const pay = () => {
        request({...transaction, type: "NO_PAYMENT"})
            .then((r) => {
                toast.success("Manual transaction added successfully");
                hideModal();
            })
            .catch((e) => {
                toast.error("An error occurred while adding transaction");
            });
    };
    const cancelSub = () => {
        request({subscription_id: sub.id, type: "CANCEL"})
            .then((r) => {
                toast.success("Subscription cancel successfully");
                hideModal();
            })
            .catch((e) => {
                toast.error("An error occurred while canceling Subscription");
            });
    };
    return (
        <form onSubmit={onSubmitHandler}>
            <h4>{sub.plan_title && "Plan title: " + sub.plan_title}</h4>
            <hr />
            <div className="form-group">
                {isRequestingPlans ? (
                    <div className="d-flex justify-content-center h-100 w-100 align-items-center">
                        <div
                            className="spinner-border  text-primary"
                            role="status"
                        ></div>
                    </div>
                ) : (
                    <div className="form-row">
                        <div className="form-col col-12 col-md-6 mb-3 mb-md-0">
                            <SingleSelect
                                type="text"
                                name="plan_id"
                                className="form-control bg-light"
                                placeholder="Plan"
                                label="Plan"
                                value={transaction.plan_id}
                                error={errors.plan_id}
                                onChange={onSelect}
                                options={plans}
                                callback={callback}
                            />
                        </div>
                        <div className="form-col col-12 col-md-6 mb-3 mb-md-0">
                            {isRequestingOptions ? (
                                <div className="d-flex justify-content-center h-100 align-items-center">
                                    <div
                                        className="spinner-border  text-primary"
                                        role="status"
                                    ></div>
                                </div>
                            ) : (
                                <SingleSelect
                                    type="text"
                                    name="subscription_length_days"
                                    className="form-control bg-light"
                                    placeholder="Price plan"
                                    label="Price plan"
                                    value={transaction.subscription_length_days}
                                    error={errors.subscription_length_days}
                                    onChange={onChangeHandler}
                                    options={options}
                                    callback={optionsCallback}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="form-row">
                <div className="form-col col-12 col-md-6 mb-3">
                    <SpinnerButton
                        className="btn btn-outline-danger btn-block"
                        loading={isRequesting}
                        type="button"
                        onClick={cancelSub}
                    >
                        Cancel Subscription
                    </SpinnerButton>
                </div>
                <div className="form-col col-12 col-md-6">
                    <SpinnerButton
                        className="btn btn-primary btn-block"
                        loading={isRequesting}
                        onClick={onSubmitHandler}
                    >
                        {isRenewing ? "Renew" : "Pay"}
                    </SpinnerButton>
                </div>
            </div>
        </form>
    );
};
export default EditClientTransactionForm;
