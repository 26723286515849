import React from "react";

const TermsAndServiesPage = () => {
    return (
        <div className="container-fluid h-100 align-items-center justify-content-center">
            <div className="row py-4 h-100">
                <div className="col-10 col-sm-8 offset-1 offset-sm-2 border">
                    <div className="px-4 py-3 h-100">
                        <iframe
                            className="w-100 h-100"
                            style={{
                                border: "0px solid #fff",
                                overflow: "hidden",
                                display: "block",
                            }}
                            src="/terms.html"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsAndServiesPage;
