export default function request(axios, data) {
    const type = data ? data.type : "";
    switch (type) {
        case "ADMIN":
            return axios.post("/admin/auth/logout", data);

        default:
            return axios.post("/client/auth/logout", data);
    }
}
