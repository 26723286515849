export default function request(axios, data) {
    const type = data ? data.type : "";
    switch (type) {
        case "ADD":
            return axios.post("/subscription/plan", data);
        case "EDIT":
            return axios.post("/subscription/plan/edit", data);
        case "DELETE":
            return axios.post("/subscription/plan/delete", data);
        default:
            return axios.get("/subscription/plans", data);
    }
}
