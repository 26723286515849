import {useClient, useClientSub, useInterval} from "hooks";
import React, {useState} from "react";
import NotificationDropdown from "./NotificationDropdown";
import {FormattedTime} from "react-intl";

const Navbar = ({onClick, multilingual}) => {
    const [user] = useClient();
    const [time, setTime] = useState(new Date(Date.now()));
    const [{timezone}] = useClientSub();
    useInterval(() => {
        setTime(new Date(Date.now()));
    }, 1000);
    return (
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            {onClick && (
                <button
                    id="sidebarToggleTop"
                    className="btn btn-link d-md-none rounded-circle mr-3"
                    onClick={onClick}
                >
                    <i className="fa fa-bars"></i>
                </button>
            )}
            {multilingual ? (
                <div>
                    {timezone ? (
                        <FormattedTime
                            value={time}
                            dateStyle="medium"
                            timeStyle="long"
                            timeZone={timezone}
                        />
                    ) : (
                        <div className="d-flex justify-content-center h-100 align-items-center">
                            <div
                                className="spinner-border  text-primary"
                                role="status"
                            ></div>
                        </div>
                    )}
                </div>
            ) : (
                <FormattedTime
                    value={time}
                    dateStyle="medium"
                    timeStyle="long"
                />
            )}
            <ul className="navbar-nav ml-auto">
                {user.username && (
                    <li className="nav-item d-flex justify-content-center align-items-center">
                        <span className="mr-2 d-none d-lg-inline text-gray-600">
                            {user.username}
                        </span>
                    </li>
                )}
                {multilingual && <NotificationDropdown />}
            </ul>
        </nav>
    );
};
export default Navbar;
