import React from "react";
import {Modal} from "utils";
import {FaPlus} from "react-icons/fa";
import {TransactionsForm} from "components/forms";

const AddTransactionsModel = ({triggerFetch}) => {
    return (
        <>
            <Modal id="add-transaction" header="Add Transaction" size="lg">
                <>
                    <a
                        data-toggle="modal"
                        data-target="#add-transaction"
                        href="/"
                        className="btn btn-primary btn-icon-split d-none d-sm-inline-block p-0"
                        onClick={(e) => e.preventDefault()}
                    >
                        <span className="icon">
                            <FaPlus />
                        </span>
                        <span className="text">Add</span>
                    </a>
                    <a
                        data-toggle="modal"
                        data-target="#add-transaction"
                        className="btn-circle btn-primary d-inline-flex d-sm-none"
                        href="/"
                        onClick={(e) => e.preventDefault()}
                    >
                        <FaPlus />
                    </a>
                </>
                <TransactionsForm triggerFetch={triggerFetch} />
            </Modal>
        </>
    );
};

export default AddTransactionsModel;
