import vest, {validate, test, enforce} from "vest";

export default function loginFormValidations(data, field, formatMessage) {
    return validate("EmailLoginForm", () => {
        vest.only(field);
        ["email", "password"].forEach((elem) => {
            test(
                elem,
                formatMessage({
                    id: "required_field",
                    defaultMessage: "This field is required",
                }),
                () => {
                    enforce(data[elem].toString()).isNotEmpty();
                }
            );
        });
        test(
            "password",
            formatMessage({
                id: "password_max_8",
                defaultMessage: "Password should be at least 8 characters long",
            }),
            () => {
                enforce(data.password.toString()).longerThanOrEquals(8);
            }
        );
    });
}
