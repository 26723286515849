import vest, {validate, test, enforce} from "vest";

export default function subscriptionFormValidations(
    data,
    field,
    formatMessage
) {
    return validate("SubscriptionPlanForm", () => {
        vest.only(field);
        [
            "title",
            // "price_per_month",
            // "price_currency",
            "maximum_number_of_devices",
            "storage_megabytes",
        ].forEach((elem) => {
            test(elem, "This field is required", () => {
                enforce(data[elem].toString()).isNotEmpty();
            });
        });
        [
            // "price_per_month",
            "maximum_number_of_devices",
            "storage_megabytes",
        ].forEach((elem) => {
            test(elem, "This should be a number", () => {
                enforce(data[elem]).matches(/^[0-9]*.{1}[0-9]*$/g);
            });
        });
    });
}
