import {useClient, useRequest, useValidation} from "hooks";
import React, {useEffect, useState} from "react";
import {clientChangeEmailRequest} from "requests";
import {SpinnerButton, ValidatedInput} from "utils";
import {changeEmailValidations} from "./validations";
import {useIntl} from "react-intl";
import bootbox from "bootbox";
import {toast} from "react-toastify";

const ChangeEmailForm = () => {
    const [loadedEmail, requesting] = useClient();
    const [email, setEmail] = useState({email: ""});
    const [verify, setVerify] = useState(false);
    const {errors, validate} = useValidation(changeEmailValidations);
    const [request, isRequesting] = useRequest(clientChangeEmailRequest);
    const {formatMessage} = useIntl();
    const success = formatMessage({
        id: "account_email_success",
        defaultMessage: "New email saved successfully!",
    });
    const error = formatMessage({
        id: "error_occurred",
        defaultMessage: "An error occurred!",
    });
    useEffect(() => {
        setEmail(loadedEmail);
    }, [loadedEmail]);
    const onChangeHandler = ({target: {name, value}}) => {
        const newEmail = {...email, [name]: value};
        setEmail(newEmail);
        validate(newEmail, name).catch(() => {});
    };
    const onSubmitHandler = (e) => {
        e.preventDefault();
        validate(email)
            .then(() => {
                bootbox.prompt({
                    title: formatMessage({
                        id: "confirm_password",
                        defaultMessage: "Please confirm your password",
                    }),
                    centerVertical: true,
                    inputType: "password",
                    callback: function (password) {
                        if (password)
                            request({...email, password})
                                .then(() => {
                                    toast.success(success);
                                    setVerify(true);
                                })
                                .catch(() => {
                                    toast.error(error);
                                });
                    },
                });
            })
            .catch((e) => {});
    };
    return (
        <>
            <form onSubmit={onSubmitHandler}>
                <div className="form-row">
                    <div className="form-col col-12">
                        <ValidatedInput
                            type="text"
                            className="form-control"
                            placeholder={formatMessage({
                                id: "username",
                                defaultMessage: "Username",
                            })}
                            label={formatMessage({
                                id: "username",
                                defaultMessage: "Username",
                            })}
                            value={email.username}
                            disabled
                        />
                    </div>
                    <div className="form-col col-12">
                        <ValidatedInput
                            type="text"
                            name="email"
                            className="form-control"
                            placeholder={formatMessage({
                                id: "email",
                                defaultMessage: "Email",
                            })}
                            label={formatMessage({
                                id: "email",
                                defaultMessage: "Email",
                            })}
                            value={email.email}
                            error={errors.email}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="form-col col-12 mt-3">
                        <SpinnerButton
                            className="btn btn-primary btn-block"
                            loading={isRequesting || requesting}
                        >
                            {formatMessage({
                                id: "save",
                                defaultMessage: "Save",
                            })}
                        </SpinnerButton>
                    </div>
                </div>
            </form>
            {verify && (
                <div class="alert alert-danger mt-2" role="alert">
                    {formatMessage({
                        id: "verify_email",
                        defaultMessage: "Please verify your email",
                    })}
                </div>
            )}
        </>
    );
};
export default ChangeEmailForm;
