import {useClient, useRequest, useValidation} from "hooks";
import React, {useEffect, useState} from "react";
import {clientChangeInfoRequest} from "requests";
import {SpinnerButton, ValidatedInput, SingleSelect, countries} from "utils";
import {changeInfoValidations} from "./validations";
import {useIntl} from "react-intl";
import {toast} from "react-toastify";

const ChangeAddressForm = () => {
    const [loadedUser, requesting] = useClient();
    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        phone: "",
        company_name: "",
        eu_vat: "",
        address_line_1: "",
        address_line_2: "",
        postal_code: "",
        country_code: "",
    });
    const {errors, validate} = useValidation(changeInfoValidations);
    const [request, isRequesting] = useRequest(clientChangeInfoRequest);
    const {formatMessage} = useIntl();
    useEffect(() => {
        setUser(loadedUser);
    }, [loadedUser]);
    const onChangeHandler = ({target: {name, value}}) => {
        const newUser = {...user, [name]: value};
        setUser(newUser);
        validate(newUser, name).catch((e) => {});
    };
    const onSubmitHandler = (e) => {
        e.preventDefault();
        const data = {
            profile_information: {
                first_name: user.first_name,
                last_name: user.last_name,
                phone: user.phone,
                address: {
                    line_1: user.address_line_1,
                    line_2: user.address_line_2,
                    postal_code: user.postal_code,
                    country_code: user.country_code,
                },
            },
            company_information: {
                company_name: user.company_name,
                vat_number: user.eu_vat,
            },
        };
        validate(user)
            .then((r) => {
                request(data)
                    .then((r) => {
                        toast.success(
                            formatMessage({
                                id: "account_info_success",
                                defaultMessage:
                                    "Account info saved successfully!",
                            })
                        );
                    })
                    .catch((e) => {
                        toast.error(
                            formatMessage({
                                id: "error_occurred",
                                defaultMessage: "An error occurred!",
                            })
                        );
                    });
            })
            .catch((e) => {});
    };
    const callback = (country, i) => {
        return (
            <option value={country.value} key={i}>
                {country.name}
            </option>
        );
    };
    return (
        <form onSubmit={onSubmitHandler}>
            <div className="form-row">
                <div className="form-col col-12 col-md-6">
                    <ValidatedInput
                        type="text"
                        name="first_name"
                        className="form-control"
                        placeholder={formatMessage({
                            id: "first_name",
                            defaultMessage: "First name",
                        })}
                        label={formatMessage({
                            id: "first_name",
                            defaultMessage: "First name",
                        })}
                        value={user.first_name}
                        error={errors.first_name}
                        onChange={onChangeHandler}
                    />
                </div>
                <div className="form-col col-12 col-md-6">
                    <ValidatedInput
                        type="text"
                        name="last_name"
                        className="form-control"
                        placeholder={formatMessage({
                            id: "last_name",
                            defaultMessage: "Last name",
                        })}
                        label={formatMessage({
                            id: "last_name",
                            defaultMessage: "Last name",
                        })}
                        value={user.last_name}
                        error={errors.last_name}
                        onChange={onChangeHandler}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-col col-12 col-md-4">
                    <ValidatedInput
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder={formatMessage({
                            id: "phone",
                            defaultMessage: "Phone",
                        })}
                        label={formatMessage({
                            id: "phone",
                            defaultMessage: "Phone",
                        })}
                        value={user.phone}
                        error={errors.phone}
                        onChange={onChangeHandler}
                    />
                </div>
                <div className="form-col col-12 col-md-4">
                    <ValidatedInput
                        type="text"
                        name="company_name"
                        className="form-control"
                        placeholder={formatMessage({
                            id: "company_name",
                            defaultMessage: "Company name",
                        })}
                        label={formatMessage({
                            id: "company_name",
                            defaultMessage: "Company name",
                        })}
                        value={user.company_name}
                        error={errors.company_name}
                        onChange={onChangeHandler}
                    />
                </div>
                <div className="form-col col-12 col-md-4">
                    <ValidatedInput
                        type="text"
                        name="eu_vat"
                        className="form-control"
                        placeholder={formatMessage({
                            id: "company_eu_vat",
                            defaultMessage: "VAT number",
                        })}
                        label={formatMessage({
                            id: "company_eu_vat",
                            defaultMessage: "VAT number",
                        })}
                        value={user.eu_vat}
                        error={errors.eu_vat}
                        onChange={onChangeHandler}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-col col-12 col-md-6">
                    <ValidatedInput
                        className="form-control"
                        type="text"
                        name="address_line_1"
                        placeholder={formatMessage({
                            id: "address_line_1",
                            defaultMessage: "Address line 1",
                        })}
                        label={formatMessage({
                            id: "address_line_1",
                            defaultMessage: "Address line 1",
                        })}
                        value={user.address_line_1}
                        error={errors.address_line_1}
                        onChange={onChangeHandler}
                    />
                    <ValidatedInput
                        className="form-control"
                        type="text"
                        name="address_line_2"
                        placeholder={formatMessage({
                            id: "address_line_2",
                            defaultMessage: "Address line 2",
                        })}
                        label={formatMessage({
                            id: "address_line_2",
                            defaultMessage: "Address line 2",
                        })}
                        value={user.address_line_2}
                        error={errors.address_line_2}
                        onChange={onChangeHandler}
                    />
                </div>
                <div className="form-col col-12 col-md-6">
                    <SingleSelect
                        options={countries}
                        placeholder={formatMessage({
                            id: "select_country",
                            defaultMessage: "Select A country",
                        })}
                        name="country_code"
                        label={formatMessage({
                            id: "country",
                            defaultMessage: "Country",
                        })}
                        error={errors.country_code}
                        onChange={onChangeHandler}
                        value={user.country_code}
                        callback={callback}
                    />

                    <ValidatedInput
                        type="text"
                        name="postal_code"
                        className="form-control"
                        placeholder={formatMessage({
                            id: "postal_code",
                            defaultMessage: "Postal code",
                        })}
                        label={formatMessage({
                            id: "postal_code",
                            defaultMessage: "Postal code",
                        })}
                        value={user.postal_code}
                        error={errors.postal_code}
                        onChange={onChangeHandler}
                    />
                </div>
            </div>
            <div className="mt-4">
                <SpinnerButton
                    className="btn btn-primary btn-block"
                    loading={isRequesting || requesting}
                >
                    {formatMessage({id: "save", defaultMessage: "Save"})}
                </SpinnerButton>
            </div>
        </form>
    );
};
export default ChangeAddressForm;
