import {AdminSideBar} from "components/sidebar";
import {
    EDIT_STRIPE_KEYS,
    LIST_CLIENTS,
    LIST_SUBSCRIPTIONS,
    LIST_TRANSACTIONS,
    SUPER_ADMIN,
    UPDATE_SMTP_DETAILS,
    VIEW_LOGS,
} from "config/privilege";
import {usePrivilege} from "hooks";
import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {routes as r} from "routes";
import * as pages from "./";

const AdminDashboardTabs = () => {
    const {canAccess} = usePrivilege();
    return (
        <AdminSideBar>
            <Switch>
                <Route
                    exact
                    path={r.adminDashboard}
                    component={pages.AdminDashboardPage}
                />
                {canAccess(SUPER_ADMIN) && (
                    <Route
                        path={r.adminManagers}
                        component={pages.AdminManagersPage}
                    />
                )}
                {canAccess(LIST_SUBSCRIPTIONS) && (
                    <Route
                        path={r.adminSubscriptionPlans}
                        component={pages.AdminSubscriptionPlans}
                    />
                )}
                {canAccess(LIST_CLIENTS) && (
                    <Route
                        path={r.adminClients}
                        component={pages.AdminClientsPage}
                    />
                )}
                {canAccess(LIST_TRANSACTIONS) && (
                    <Route
                        path={r.adminTransactions}
                        component={pages.AdminTransactionsPage}
                    />
                )}
                {/* <Route
                    path={r.adminPointXVersions}
                    component={pages.AdminPointXPage}
                /> */}
                {canAccess(VIEW_LOGS) && (
                    <Route
                        path={r.adminLogsView}
                        component={pages.AdminLogsPage}
                    />
                )}
                {!(
                    !canAccess(EDIT_STRIPE_KEYS) &&
                    !canAccess(UPDATE_SMTP_DETAILS)
                ) && (
                    <Route
                        path={r.adminGlobalSettings}
                        component={pages.AdminSettingsPage}
                    />
                )}
            </Switch>
        </AdminSideBar>
    );
};
export default AdminDashboardTabs;
