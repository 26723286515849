import React, {useEffect, useState} from "react";
import {useRequest, useValidation} from "hooks";
import {
    adminClientRequests,
    adminTransactionsRequests,
    clientSubscriptionsRequest as getPlans,
    planOptions,
} from "requests";
import {SingleSelect, SpinnerButton} from "utils";
import {transactionFormValidations} from "./validations";
// import {FormattedNumber} from "react-intl";
import $ from "jquery";
import {toast} from "react-toastify";
import {confirm} from "bootbox";

const defaultTransaction = {
    subscription_length_days: "",
    client_username: "",
    plan_id: "",
};

const TransactionsForm = ({triggerFetch}) => {
    const [transaction, setTransaction] = useState(defaultTransaction);
    const [plans, setPlans] = useState([]);
    const [clients, setClients] = useState([]);
    const {errors, validate} = useValidation(transactionFormValidations);
    const [request, isRequesting] = useRequest(adminTransactionsRequests);
    const [planRequest, isRequestingPlans] = useRequest(getPlans);
    const [clientRequest, isRequestingClient] = useRequest(adminClientRequests);
    const [optionsRequest, isRequestingOptions] = useRequest(planOptions);
    const [options, setOptions] = useState([]);
    // const [requestClients, requestingClients] = useRequest(adminClientRequests);

    const fetchData = () => {
        clientRequest()
            .then(({data}) => {
                const _clients = data.data.clients;
                const adapterClients = [];
                _clients.forEach((client) => {
                    adapterClients.push({username: client.username});
                });
                setClients(adapterClients);
            })
            .catch((e) => {});
    };
    useEffect(() => {
        fetchData();
        planRequest()
            .then(({data}) => {
                const plans = data.data.plans;
                setPlans(plans.filter((plan) => plan.plan_id !== "FREE"));
            })
            .catch((e) => {});
    }, []);
    const onChangeHandler = ({target: {name, value}}) => {
        const newTransaction = {...transaction, [name]: value};
        setTransaction(newTransaction);
        validate(newTransaction, name).catch((e) => {});
    };
    const onSelect = ({target: {name, value}}) => {
        let newTransaction = {...transaction, [name]: value};
        optionsRequest({id: value})
            .then((r) => {
                setOptions(r.data.data.plan.pricing);
                newTransaction.subscription_length_days = "";
            })
            .catch((e) => {});
        setTransaction(newTransaction);
        validate(newTransaction, name).catch((e) => {});
    };
    const addTransaction = () => {
        request({...transaction, type: "ADD"})
            .then((r) => {
                toast.success("Manual transaction added successfully");
                triggerFetch(Math.random());
                $("#add-transaction").modal("hide");
                $("body").removeClass("modal-open");
                $(".modal-backdrop").remove();
            })
            .catch((e) => {
                toast.error(
                    "An error occurred while adding manual transaction"
                );
            });
    };
    const onSubmitHandler = (e) => {
        e.preventDefault();
        validate(transaction)
            .then((r) => {
                clientRequest({
                    type: "SUB",
                    client_username: transaction.client_username,
                })
                    .then((r) => {
                        let sub = {};
                        if (r.data.data.subscriptions.length)
                            sub = r.data.data.subscriptions[0];
                        const storage = sub.allocated_storage_megabytes || 0;
                        const plan = plans
                            .filter((p) => p.plan_id === transaction.plan_id)
                            .pop();
                        if (storage > plan.storage_megabytes)
                            confirm({
                                message:
                                    "Subscribing to this plan will void all the data in client since it has less resources",
                                centerVertical: true,
                                buttons: {
                                    confirm: {
                                        label: "Continue",
                                        className: "btn-danger",
                                    },
                                    cancel: {
                                        label: "Cancel",
                                        className: "btn-outline-primary",
                                    },
                                },
                                callback: (result) => {
                                    if (result) addTransaction();
                                },
                            });
                        else addTransaction();
                    })
                    .catch((e) => {});
            })
            .catch((e) => {});
    };
    const callback = (plan, i) => {
        return (
            <option value={plan.plan_id} key={i}>
                {plan.title}
            </option>
        );
    };
    const optionsCallback = (price, i) => {
        return (
            <option value={price.subscription_length_days} key={i}>
                {price.subscription_length_days +
                    " for " +
                    price.price_amount +
                    " " +
                    price.price_currency}
            </option>
        );
    };
    const clientsCallback = (client, i) => {
        return (
            <option value={client.username} key={i}>
                {client.username}
            </option>
        );
    };
    return (
        <form className="user" onSubmit={onSubmitHandler}>
            <div className="form-group">
                {isRequestingPlans ? (
                    <div className="d-flex justify-content-center h-100 w-100 align-items-center">
                        <div
                            className="spinner-border  text-primary"
                            role="status"
                        ></div>
                    </div>
                ) : (
                    <div className="form-row">
                        <div className="form-col col-12 col-md-4 mb-3 mb-md-0">
                            <SingleSelect
                                type="text"
                                name="plan_id"
                                className="form-control bg-light"
                                placeholder="Plan"
                                label="Plan"
                                value={transaction.plan_id}
                                error={errors.plan_id}
                                onChange={onSelect}
                                options={plans}
                                callback={callback}
                            />
                        </div>
                        <div className="form-col col-12 col-md-4 mb-3 mb-md-0">
                            {isRequestingOptions ? (
                                <div className="d-flex justify-content-center h-100 align-items-center">
                                    <div
                                        className="spinner-border  text-primary"
                                        role="status"
                                    ></div>
                                </div>
                            ) : (
                                <SingleSelect
                                    type="text"
                                    name="subscription_length_days"
                                    className="form-control bg-light"
                                    placeholder="Price plan"
                                    label="Price plan"
                                    value={transaction.subscription_length_days}
                                    error={errors.subscription_length_days}
                                    onChange={onChangeHandler}
                                    options={options}
                                    callback={optionsCallback}
                                />
                            )}
                        </div>
                        <div className="form-col col-12 col-md-4">
                            <SingleSelect
                                name="client_username"
                                className="form-control bg-light"
                                placeholder="Username"
                                label="Username"
                                value={transaction.client_username}
                                error={errors.client_username}
                                options={clients}
                                onChange={onChangeHandler}
                                callback={clientsCallback}
                            />
                        </div>
                    </div>
                )}
            </div>
            <SpinnerButton
                className="btn btn-primary btn-block"
                loading={isRequesting || isRequestingClient}
            >
                Pay
            </SpinnerButton>
        </form>
    );
};
export default TransactionsForm;
