import React, {useEffect, useState} from "react";
import {Slider} from "utils";
import "./PricingCarousel.css";
import {GiCheckMark} from "react-icons/gi";
import {useRequest} from "hooks";
import {clientSubInfo, clientSubscriptionsRequest} from "requests";
import {FormattedMessage, FormattedNumber, useIntl} from "react-intl";
import {confirm} from "bootbox";
import $ from "jquery";
import moment from "moment";

const PriceCard = ({subscription, i, onClick, myPlan}) => {
    const {
        plan_id = "ID",
        title = "",
        maximum_number_of_devices = 10,
        storage_megabytes = 2048,
    } = subscription;
    const currentPlan = myPlan && myPlan.plan_id === plan_id;
    const disabled = plan_id === "FREE" || currentPlan;
    return (
        <div
            className={`card-hover text-center ${
                currentPlan ? "current" : disabled ? "disabled" : ""
            }`}
            onClick={(e) => !disabled && onClick(subscription)}
        >
            <div className="title border-bottom">
                <h2 className="mb-1">
                    <FormattedMessage
                        id="plan_title"
                        defaultMessage="{title}"
                        values={{title}}
                    />
                </h2>
            </div>
            <div className="price">
                <h4>
                    <FormattedMessage
                        id="plan_card_title"
                        defaultMessage="Plan {i}"
                        values={{i: i}}
                    />
                </h4>
            </div>
            <div className="option text-center">
                <ul>
                    {/* <li>
                            <GiCheckMark />
                            <FormattedMessage
                                id="plan_time_interval"
                                defaultMessage="Period: {months}"
                                values={{
                                    months: Math.floor(
                                        interval_duration_days / 30
                                    ),
                                }}
                            />
                        </li> */}
                    <li>
                        <GiCheckMark />
                        <FormattedMessage
                            id="plan_maximum_number_of_devices"
                            defaultMessage="Maximum devices"
                        />
                        <br />
                        {/* <div className="text-center"> */}
                        <b>{maximum_number_of_devices}</b>
                        {/* </div> */}
                    </li>
                    <li>
                        <GiCheckMark />
                        <FormattedMessage
                            id="plan_maximum_storage_bytes"
                            defaultMessage="Maximum storage"
                        />
                        <br />
                        <b>
                            <FormattedNumber
                                style="unit"
                                unit="gigabyte"
                                value={storage_megabytes / 1024}
                                unitDisplay="narrow"
                            />
                        </b>
                    </li>
                </ul>
            </div>
        </div>
    );
};

const PricingCarousel = ({setPlan = () => {}}) => {
    const [request, requesting] = useRequest(clientSubscriptionsRequest);
    const [subscriptions, setSubs] = useState([]);
    const [sub, setSub] = useState({});
    const [subRequest] = useRequest(clientSubInfo);
    const {formatMessage} = useIntl();

    useEffect(() => {
        subRequest()
            .then((r) => {
                setSub(r.data.data.subscriptions[0]);
            })
            .catch((e) => {});
    }, []);

    useEffect(() => {
        request()
            .then(({data}) => {
                let plans = data.data.plans;
                plans.sort((a, b) =>
                    moment(a.created_at).diff(b.created_at, "millisecond")
                );
                setSubs(plans);
            })
            .catch((e) => {});
    }, []);
    const onClick = (plan) => {
        if (
            plan.storage_megabytes < sub.allocated_storage_megabytes ||
            plan.maximum_number_of_devices < sub.maximum_number_of_devices
        )
            confirm({
                message: formatMessage({
                    id: "storage_warning",
                    defaultMessage:
                        "Subscribing to this plan will void all the data in your current subscription since it has less resources",
                }),
                // size: "small",
                centerVertical: true,
                buttons: {
                    confirm: {
                        label: formatMessage({
                            id: "continue",
                            defaultMessage: "Continue",
                        }),
                        className: "btn-danger",
                    },
                    cancel: {
                        label: formatMessage({
                            id: "cancel",
                            defaultMessage: "Cancel",
                        }),
                        className: "btn-outline-primary",
                    },
                },
                callback: async (result) => {
                    setPlan(plan);
                    if (result) $("#subscribe").modal("show");
                },
            });
        else {
            setPlan(plan);
            $("#subscribe").modal("show");
        }
    };
    return (
        <div className="container mx-2 my-4">
            {requesting ? (
                <div className="d-flex justify-content-center">
                    <div
                        className="spinner-border  text-primary"
                        role="status"
                    ></div>
                </div>
            ) : subscriptions.length > 0 ? (
                <Slider>
                    {subscriptions.map((p, i) => (
                        <PriceCard
                            onClick={onClick}
                            key={i}
                            subscription={p}
                            myPlan={sub}
                            i={i + 1}
                        />
                    ))}
                </Slider>
            ) : (
                <div className="d-flex justify-content-center">
                    <div>
                        <FormattedMessage
                            id="no_plans"
                            defaultMessage="No plans are currencly registered in the system"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default PricingCarousel;
