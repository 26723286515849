export default function request(axios, data) {
    const type = data ? data.type : "";
    switch (type) {
        case "ADD":
            return axios.post("/billing/manual-pay", data);
        case "DELETE":
            return axios.post("/billing/transaction/cancel", data);
        default:
            return axios.get("/billing/admin", data);
    }
}
