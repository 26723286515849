import React from "react";
import {SideBar} from "components/sidebar";
import {Switch, Route, Redirect} from "react-router-dom";
import * as pages from "./";
import * as r from "routes/routes";
import {Title} from "utils";

const ClientDashboardTabs = () => {
    return (
        <SideBar>
            <Switch>
                <Route exact path={r.dashboardRoute}>
                    <Redirect to={r.redirectToPXRoute} />
                </Route>
                <Route path={r.editProfileRoute}>
                    <Title title="Profile - PointX CM" />
                    <pages.EditProfilePage />
                </Route>
                <Route path={r.editSettings}>
                    <Title title="Settings - PointX CM" />
                    <pages.ClientSettingsPage />
                </Route>
                <Route path={r.transactionsRoute}>
                    <Title title="Transactions - PointX CM" />
                    <pages.TransactionsPage />
                </Route>
                <Route path={r.pricingPlansRoute}>
                    <Title title="Subscription - PointX CM" />
                    <pages.PricingPage />
                </Route>
                <Route path={r.paymentSuccessRoute}>
                    <Title title="Payment success - PointX CM" />
                    <pages.ClientDashboardPage success />
                </Route>
                <Route path={r.paymentCancelRoute}>
                    <Title title="Payment cancelled - PointX CM" />
                    <pages.ClientDashboardPage cancel />
                </Route>
                <Route path={r.redirectToPXRoute}>
                    <Title title="Dashboard - PointX CM" />
                    <pages.ClientDashboardPage />
                </Route>
            </Switch>
        </SideBar>
    );
};
export default ClientDashboardTabs;
