import vest, {validate, test, enforce} from "vest";

export default function changeAddressValidations(data, field, formatMessage) {
    return validate("EmailLoginForm", () => {
        vest.only(field);
        [
            "first_name",
            "last_name",
            "phone",
            "company_name",
            "address_line_1",
            "postal_code",
            "country_code",
        ].forEach((elem) => {
            test(
                elem,
                formatMessage({
                    id: "required_field",
                    defaultMessage: "This field is required",
                }),
                () => {
                    enforce(data[elem].toString()).isNotEmpty();
                }
            );
        });
    });
}
