import React, {useState, useEffect} from "react";
import {useRequest} from "hooks";
import {adminLogsRequests} from "requests";
import {DateInput} from "utils";
import moment from "moment";
import Table from "./Table";

const AdminLogsTable = () => {
    const options = {
        filter: false,
        sortOrder:{
            name:"created_at",
            direction:"desc"
        }
    };
    const columns = [
        {
            label: "Log time",
            name: "created_at",
            options: {
                sort: true,
                filter: false,
                customBodyRenderLite: (row) => (
                    <div className="mx-2 py-3">
                        {moment(data[row].created_at).format(
                            "DD/MM/YYYY [at] hh:mm a"
                        )}
                    </div>
                ),
            },
        },
        {
            label: "Log author",
            name: "author",
            options: {filter: false, sort: false},
        },
        {
            label: "Log text",
            name: "entry",
            options: {filter: false, sort: false},
        },
    ];

    const [data, setData] = useState([
        {
            id: "id",
            created_at: "datetime",
            author: "author",
            entry: "Some thing interesting happened",
        },
    ]);

    const [request, requesting] = useRequest(adminLogsRequests);
    const [date, setDate] = useState(new Date());

    const fetchData = () => {
        let format = moment(date).format("YYYY-MM-DD");
        request({date: format})
            .then(({data}) => {
                setData(data.data.logs);
            })
            .catch(() => {});
    };

    useEffect(fetchData, []);
    useEffect(fetchData, [date]);

    const SearchByDate = () => {
        const onDateChange = ({target: {value}}) => {
            setDate(value);
        };
        return (
            <div className="row no-gutter">
                <div className="col-12 col-md-3 mb-2">
                    <DateInput
                        type="text"
                        className="form-control bg-light"
                        type="text"
                        label="Select a date"
                        placeholder="Select a date"
                        value={date}
                        onChange={onDateChange}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {/* <SearchByDate /> */}
            <Table
                columns={columns}
                data={data}
                loading={requesting}
                options={options}
                components={{TableFilterList: SearchByDate}}
            />
        </>
    );
};
export default AdminLogsTable;
