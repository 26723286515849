import {useRequest, useValidation} from "hooks";
import React, {useState} from "react";
import {clientChangePasswordRequest} from "requests";
import {SpinnerButton, ValidatedInput} from "utils";
import {changePasswordValidations} from "./validations";
import {useIntl} from "react-intl";
import {toast} from "react-toastify";
import bootbox from "bootbox";

const ChangePasswordForm = () => {
    const [password, setPassword] = useState({
        password_confirm: "",
        password: "",
    });
    const {errors, validate, hasErrors} = useValidation(
        changePasswordValidations
    );
    const [request, isRequesting] = useRequest(clientChangePasswordRequest);
    const {formatMessage} = useIntl();
    const success = formatMessage({
        id: "account_password_success",
        defaultMessage: "New password saved successfully!",
    });
    const error = formatMessage({
        id: "error_occurred",
        defaultMessage: "An error occurred!",
    });
    const onChangeHandler = ({target: {name, value}}) => {
        const newPassword = {...password, [name]: value};
        setPassword(newPassword);
        validate(newPassword, name).catch((e) => {});
    };
    const onSubmitHandler = (e) => {
        e.preventDefault();
        const data = {
            new_password: password.password,
        };
        validate(password)
            .then((r) => {
                bootbox.prompt({
                    title: formatMessage({
                        id: "confirm_password",
                        defaultMessage: "Please confirm your password",
                    }),
                    inputType: "password",
                    centerVertical: true,
                    callback: function (old_password) {
                        if (old_password)
                            request({...data, old_password})
                                .then((r) => {
                                    toast.success(success);
                                })
                                .catch((e) => {
                                    toast.error(error);
                                });
                    },
                });
            })
            .catch((e) => {});
    };
    return (
        <form onSubmit={onSubmitHandler}>
            <div className="form-row">
                <div className="form-col col-12">
                    <ValidatedInput
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder={formatMessage({
                            id: "password",
                            defaultMessage: "Password",
                        })}
                        label={formatMessage({
                            id: "password",
                            defaultMessage: "Password",
                        })}
                        value={password.password}
                        error={errors.password}
                        onChange={onChangeHandler}
                    />
                </div>
                <div className="form-col col-12">
                    <ValidatedInput
                        type="password"
                        name="password_confirm"
                        className="form-control"
                        placeholder={formatMessage({
                            id: "password_confirm",
                            defaultMessage: "Password confirmation",
                        })}
                        label={formatMessage({
                            id: "password_confirm",
                            defaultMessage: "Password confirmation",
                        })}
                        value={password.password_confirm}
                        onChange={onChangeHandler}
                        error={errors.password_confirm}
                    />
                </div>
                <div className="form-col col-12 mt-3">
                    <SpinnerButton
                        className="btn btn-primary btn-block"
                        loading={isRequesting}
                    >
                        {formatMessage({id: "save", defaultMessage: "Save"})}
                    </SpinnerButton>
                </div>
            </div>
        </form>
    );
};
export default ChangePasswordForm;
