import React, {useEffect, useState} from "react";
import {Card, SpinnerButton} from "utils";
import {useIntl} from "react-intl";
import {useRequest} from "hooks";
import {
    clientbillingInfo,
    clientSubInfo,
    clientSubscribeToPlan,
} from "requests";
import {confirm} from "bootbox";
import $ from "jquery";
import {toast} from "react-toastify";

const BillingCard = ({setPlan}) => {
    const {formatMessage} = useIntl();
    const [request, isRequesting] = useRequest(clientbillingInfo);
    const [disabled, setDisabled] = useState(false);
    const [sub, setSub] = useState({});
    const [requestSubInfo, requestingSubInfo] = useRequest(clientSubInfo);
    const [renewRequest, isRequestingRenewal] = useRequest(
        clientSubscribeToPlan
    );

    useEffect(() => {
        requestSubInfo()
            .then((r) => {
                const s = r.data.data.subscriptions[0];
                setSub(s);
                setDisabled(!s.is_renewed_automatically);
            })
            .catch((e) => {})
            .finally(() => {});
    }, []);

    const goToStripe = () => {
        request()
            .then((r) => {
                let url = r.data.data.url;
                window.open(url, "_blank");
            })
            .catch(() => {});
    };

    const disableRenewal = () => {
        const payload = {
            // plan_id: sub.plan_id,
            subscription_id: sub.id,
            // automatic_renewal: automatic_renewal,
            type: "CANCEL",
        };
        renewRequest({...payload, type: "CANCEL"})
            .then((r) => {
                toast.success(
                    formatMessage({
                        id: "renewal_disable_success",
                        defaultMessage: "Auto renewal disabled successfully",
                    })
                );
                requestSubInfo()
                    .then((r) => {
                        const s = r.data.data.subscriptions[0];
                        setSub(s);
                        setDisabled(!s.is_renewed_automatically);
                    })
                    .catch((e) => {})
                    .finally(() => {});
            })
            .catch(() => {
                toast.error(
                    formatMessage({
                        id: "renewal_disable_error",
                        defaultMessage:
                            "An error occurred while disabling auto renewal",
                    })
                );
            });
    };

    const onClick = () => {
        if (!disabled)
            confirm({
                message: formatMessage({
                    id: "renewal_warning",
                    defaultMessage:
                        "Your subscription will expire if not renewed automatically and all your PointX data will be lost",
                }),
                // size: "small",
                centerVertical: true,
                buttons: {
                    confirm: {
                        label: formatMessage({
                            id: "continue",
                            defaultMessage: "Continue",
                        }),
                        className: "btn-danger",
                    },
                    cancel: {
                        label: formatMessage({
                            id: "cancel",
                            defaultMessage: "Cancel",
                        }),
                        className: "btn-outline-primary",
                    },
                },
                callback: async (result) => {
                    if (result) disableRenewal();
                },
            });
        else {
            showSubModal();
        }
    };

    const showSubModal = () => {
        setPlan({plan_id: sub.plan_id, subscription_id: sub.id});
        $("#subscribe").modal("show");
    };
    return (
        sub.plan_id !== "FREE" && (
            <Card
                header={formatMessage({
                    id: "billing",
                    defaultMessage: "Billing",
                })}
            >
                {requestingSubInfo ? (
                    <div className="d-flex justify-content-center">
                        <div
                            className="spinner-border  text-primary"
                            role="status"
                        ></div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-12 col-md-6 mb-2 mb-md-0">
                            <SpinnerButton
                                className={`btn w-100 ${
                                    disabled
                                        ? "btn-outline-success"
                                        : "btn-outline-danger"
                                }`}
                                loading={isRequestingRenewal}
                                onClick={onClick}
                            >
                                {disabled
                                    ? formatMessage({
                                          id: "renew_sub",
                                          defaultMessage: "Renew sub",
                                      })
                                    : formatMessage({
                                          id: "disable_renewal",
                                          defaultMessage:
                                              "Disable automatic renewal",
                                      })}
                            </SpinnerButton>
                        </div>
                        <div className="col-12 col-md-6 mb-2 mb-md-0">
                            <SpinnerButton
                                className="btn btn-primary w-100"
                                onClick={goToStripe}
                                loading={isRequesting}
                            >
                                {formatMessage({
                                    id: "go_to_billing",
                                    defaultMessage: "go to Billing",
                                })}
                            </SpinnerButton>
                        </div>
                    </div>
                )}
            </Card>
        )
    );
};
export default BillingCard;
