import {truncate} from "utils";
import vest, {validate, test, enforce} from "vest";

export default function addClientValidations(data, field) {
    return validate("AddClientForm", () => {
        vest.only(field);
        [
            "first_name",
            "last_name",
            "username",
            "email",
            "phone",
            "password",
            "password_confirm",
            "company",
            "address_line_1",
            "postal_code",
            "country_code",
        ].forEach((elem) => {
            test(elem, "This field is required", () => {
                enforce(data[elem].toString()).isNotEmpty();
            });
        });

        test(
            "username",
            "Username must only contain lowercase letters, numbers and underscores",
            () => {
                enforce(data.username.toString())
                    .isNotEmpty()
                    .matches(/^[A-Za-z0-9_]*$/g);
            }
        );

        test(
            "username",
            "Username should be at most 12 characters long",
            () => {
                enforce(data.username.length).lessThanOrEquals(12);
            }
        );

        test(
            "username",
            "Username should be at least 5 characters long",
            () => {
                enforce(data.username.toString()).longerThanOrEquals(5);
            }
        );

        const trimmedEmail = truncate(data.email.toString(), 15);
        test(
            "email",
            trimmedEmail.concat(" " + "email is not valid email address"),
            () => {
                enforce(data.email.toString())
                    .isNotEmpty()
                    .matches(/[^@]+@[^.]+\..+/g);
            }
        );
        test(
            "password",
            "Password should be at least 8 characters long",
            () => {
                enforce(data.password.toString()).longerThanOrEquals(8);
            }
        );

        test("password_confirm", "Passwords should be matching", () => {
            enforce(
                !data.password
                    .toString()
                    .localeCompare(data.password_confirm.toString())
            ).isTruthy();
        });
        test("password", "Passwords should be matching", () => {
            if (data.password_confirm && data.password_confirm !== "")
                enforce(
                    !data.password
                        .toString()
                        .localeCompare(data.password_confirm.toString())
                ).isTruthy();
        });
    });
}
