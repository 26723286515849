import React, {useState, useEffect} from "react";
import {useRequest} from "hooks";
import {adminSubscriptionRequests} from "requests";
import {AiFillEdit, AiFillDelete} from "react-icons/ai";
import {confirm} from "utils";
import Table from "./Table";
import {alert} from "bootbox";
import moment from "moment";

const SubscriptionPlansTable = ({
    setPlan = () => {},
    canEdit,
    canDelete,
    fetch,
}) => {
    const Actions = (row) => (
        <div className="d-flex mx-2 py-2">
            {canEdit && (
                <button
                    type="button"
                    className="btn btn-info mr-1"
                    data-toggle="modal"
                    data-target="#edit-subscription-plan"
                    onClick={(e) => setPlan(data[row])}
                >
                    <AiFillEdit />
                </button>
            )}
            {data[row].plan_id !== "FREE" && canDelete && (
                <button
                    type="button"
                    className="btn btn-danger"
                    onClick={(e) => confirmDelete(data[row])}
                >
                    <AiFillDelete />
                </button>
            )}
        </div>
    );
    const confirmDelete = (row) =>
        confirm({
            message: "Are you sure you want to delete this subscription plan?",
            onConfirm: () => deleteSub(row),
        });
    const deleteSub = (row) => {
        request({...row, type: "DELETE"})
            .then((r) => {
                fetchData();
            })
            .catch((e) => {
                if (e.response && e.response.status === 412) {
                    alert(
                        "This plan cannot be deleted because it have clients attached"
                    );
                }
            });
    };

    const columns = [
        {
            label: "Plan ID",
            name: "plan_id",
            options: {
                sort: true,
                customBodyRenderLite: (i) => (
                    <div className="small-text">{data[i].plan_id}</div>
                ),
            },
        },
        {
            label: "Plan Title",
            name: "title",
            options: {sort: true},
        },
        {
            label: "Created at",
            name: "created_at",
            options: {
                sort: true,
                customBodyRenderLite: (row) =>
                    moment(data[row].created_at).format(
                        "DD/MM/YYYY [at] hh:mm a"
                    ),
            },
        },
        {
            label: "Maximum number of devices",
            name: "maximum_number_of_devices",
            options: {sort: true},
        },
        {
            label: "Maximum available storage",
            name: "storage_megabytes",
            options: {sort: true},
        },
        {
            label: "Actions",
            name: "",
            options: {
                sort: false,
                customBodyRenderLite:
                    canEdit || canDelete ? Actions : () => "No actions",
            },
        },
    ];

    const [data, setData] = useState([
        {
            id: "ID",
            plan_id: "PLAN_ID",
            allocated_storage_megabytes: 1024,
            maximum_number_of_devices: 22,
            created_at: new Date(),
            renewal_date: new Date(),
            client_username: "Username",
        },
    ]);

    const [request, requesting] = useRequest(adminSubscriptionRequests);
    const fetchData = () => {
        request()
            .then(({data}) => {
                setData(data.data.plans);
            })
            .catch((e) => {});
    };
    useEffect(fetchData, [fetch]);

    return (
        // <DataTable
        //     columns={columns}
        //     data={data}
        //     progressPending={requesting}
        //     pagination
        //     paginationTotalRows={totalRows}
        //     persistTableHead
        //     progressComponent={<Progress />}
        //     customStyles={customStyles}
        // />
        <Table
            data={data}
            columns={columns}
            options={{filter: true}}
            loading={requesting}
        />
    );
};
export default SubscriptionPlansTable;
