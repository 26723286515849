import {TransactionsTable} from "components/tables";
import React from "react";
import {useIntl} from 'react-intl';
const TransactionsPage = () => {
    const {formatMessage} = useIntl();
    return (
        <>
            <div className="container-fluid">
                {/* <h1 className="h3 mb-2 text-gray-800">Transactions</h1> */}

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">
                            {formatMessage({
                                id: "transactions",
                                defaultMessage: "Transactions",
                            })}
                        </h6>
                    </div>
                    <div className="card-body">
                        <TransactionsTable />
                    </div>
                </div>
            </div>
        </>
    );
};

export default TransactionsPage;
