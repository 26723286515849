import React, {useEffect, useState} from "react";
import {useRequest} from "hooks";
import {adminManagerRequests} from "requests";
import {AiFillEdit, AiFillDelete} from "react-icons/ai";
import {confirm} from "utils";
import {privileges} from "config/privilege";
import Table from "./Table";

const ManagersTable = ({setManager = () => {}, fetch}) => {
    const Actions = (i) => {
        const row = data[i];
        return (
            <div className="d-flex mx-2 py-2">
                <button
                    type="button"
                    className="btn btn-info mr-1"
                    data-toggle="modal"
                    data-target="#edit-manager"
                    onClick={() => setManager(row)}
                >
                    <AiFillEdit />
                </button>
                {row.username !== "admin" && (
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => confirmDelete(row)}
                    >
                        <AiFillDelete />
                    </button>
                )}
            </div>
        );
    };
    const confirmDelete = (row) =>
        confirm({
            message: "Are you sure you want to delete this manager?",
            onConfirm: () => deleteSub(row),
        });
    const deleteSub = (row) => {
        request({...row, type: "DELETE"})
            .then(() => {
                fetchData();
            })
            .catch(() => {});
    };

    const columns = [
        {label: "Username", name: "username"},
        {
            label: "Priviliges",
            name: "privileges",
            options: {
                sort: false,
                customBodyRenderLite: (row) => (
                    <div className="mt-1">
                        {data[row].privileges.map((p) => (
                            <span
                                className="badge badge-pill badge-primary mr-1 mb-1"
                                style={{fontSize: 12}}
                            >
                                {privileges[p]}
                            </span>
                        ))}
                    </div>
                ),
            },
        },
        {
            label: "Actions",
            name: "",
            options: {sort: false, customBodyRenderLite: Actions},
        },
    ];

    const [data, setData] = useState([
        {
            username: "USERNAME",
            privileges: [
                "SUPER_ADMIN",
                "CREATE_SUBSCRIPTION_PLANS",
                "EDIT_STRIPE_KEYS",
                "CREATE_MANUAL_TRANSACTIONS",
                "LIST_TRANSACTIONS",
                "LIST_CLIENTS",
                "EDIT_CLIENTS",
                "DELETE_CLIENTS",
                "UPDATE_SMTP_DETAILS",
                "ACCESS_POINTX",
                "DELETE_SUBSCRIPTION_PLAN",
                "EDIT_SUBSCRIPTION_PLAN",
                "LIST_SUBSCRIPTIONS",
            ],
        },
    ]);
    const [request, requesting] = useRequest(adminManagerRequests);
    const fetchData = () => {
        request().then(({data}) => {
            setData(data.data.administrators);
        });
    };
    useEffect(fetchData, [fetch]);

    return (
        <Table
            columns={columns}
            data={data}
            loading={requesting}
            options={{filter: false}}
        />
    );
};
export default ManagersTable;
