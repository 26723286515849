import vest, {validate, test, enforce} from "vest";

export default function stmpFormValidations(data, field, formatMessage) {
    return validate("SubscriptionPlanForm", () => {
        vest.only(field);
        [
            "host",
            "username",
            "password",
            "port",
            "auth_type",
            "sender_address",
            "sender_name",
        ].forEach((elem) => {
            test(elem, "This field is required", () => {
                enforce(data[elem].toString()).isNotEmpty();
            });
        });
        ["port"].forEach((elem) => {
            test(elem, "This should be a number", () => {
                enforce(data[elem]).matches(/^[0-9]*.{1}[0-9]*$/g);
            });
        });
    });
}
