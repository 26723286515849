export default function request(axios, data) {
    const type = data ? data.type : "";
    console.log(data);
    switch (type) {
        case "EDIT":
            return axios.post("/billing/plan", data);
        default:
            return axios.get(`/billing/plan/${data.id}/price`, data);
    }
}
