import {BillingCard} from "components/cards";
import {PricingCarousel} from "components/carousel";
import {SubscriptionModal} from "components/modals";
import React, {useState} from "react";
import {useIntl} from "react-intl";

const PricingPage = () => {
    const {formatMessage} = useIntl();
    const [plan, setPlan] = useState(null);
    return (
        <>
            <div className="container-fluid">
                {/* <h1 className="h3 mb-2 text-gray-800">Transactions</h1> */}
                <SubscriptionModal plan={plan} />
                <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h6 className="m-0 font-weight-bold text-primary">
                            {formatMessage({
                                id: "pricing_plans",
                                defaultMessage: "Pricing Plans",
                            })}
                        </h6>
                        {/* <div className="ml-auto">
                            <BillingCard />
                        </div> */}
                    </div>
                    <div className="card-body">
                        <PricingCarousel plan={plan} setPlan={setPlan} />
                    </div>
                </div>
                <hr />
                <BillingCard setPlan={setPlan} />
            </div>
        </>
    );
};

export default PricingPage;
