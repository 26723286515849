import {useContext, useRef, useState} from "react";
import useAxios from "./useAxios";
import {AuthContext} from "context";
import {refreshTokenRequest} from "requests";
import useLogout from "./useLogout";

export default function useRequest(request, load = false) {
    const axios = useAxios();
    const [isProcessing, setIsProcessing] = useState(false);
    const {auth, setAuth} = useContext(AuthContext);
    const {clearCookies} = useLogout();
    const axiosRef = useRef();
    axiosRef.current = axios;
    const refresh = {refresh_token: auth.refresh_token};
    const send = async (data) => {
        setIsProcessing(true);
        if (load)
            document
                .getElementById("loader")
                .classList.add("loading-indicator");
        const response = new Promise((resolve, reject) => {
            request(axios, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((e) => {
                    if (e.response)
                        switch (e.response.status) {
                            case 401:
                                if (auth.refresh_token) {
                                    refreshTokenRequest(axios, refresh)
                                        .then((r) => {
                                            setAuth({
                                                ...auth,
                                                access_token:
                                                    r.data.data.access_token,
                                            }).then(() => {
                                                request(axiosRef.current, data)
                                                    .then((res) => {
                                                        return resolve(res);
                                                    })
                                                    .catch((e) => {
                                                        return reject(e);
                                                    });
                                            });
                                        })
                                        .catch((e) => {
                                            setAuth({
                                                is_logged_in: false,
                                                access_token: null,
                                            });
                                            return reject(e);
                                        });
                                } else {
                                    clearCookies();
                                }
                                return reject(e);
                            default:
                                break;
                        }
                    reject(e);
                });
        });
        response
            .then((r) => {
                if (r.headers.authorization) {
                    const token = r.headers.authorization;
                    setAuth({...auth, access_token: token});
                }
                return Promise.resolve(r);
            })
            .finally(() => {
                setIsProcessing(false);
                if (load)
                    document
                        .getElementById("loader")
                        .classList.remove("loading-indicator");
            });
        return response;
    };
    return [send, isProcessing];
}
