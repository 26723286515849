export default function request(axios, data) {
    const type = data ? data.type : "";
    switch (type) {
        case "EDIT":
            return axios.post("/admin/account/edit", data);
        case "ADD":
            return axios.post("/admin/account", data);
        case "DELETE":
            return axios.post("/admin/account/delete", data);
        default:
            return axios.get("/admin/accounts", data);
    }
}
