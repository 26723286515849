import React from "react";
import {Modal} from "utils";
import {SubscriptionPlanForm} from "components/forms";

const EditSubscriptionPlanModal = ({plan, triggerFetch}) => {
    // alert(JSON.stringify(plan));
    return (
        <>
            <Modal
                id="edit-subscription-plan"
                header="Edit Subscription Plan"
                size="md"
            >
                <>
                    <div className="text-center border-bottom">
                        Pland ID: {plan.plan_id}
                    </div>
                    {/* <hr /> */}
                    <SubscriptionPlanForm
                        plan={plan}
                        edit
                        triggerFetch={triggerFetch}
                    />
                </>
            </Modal>
        </>
    );
};

export default EditSubscriptionPlanModal;
