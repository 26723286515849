import {FormWrapper, RegisterForm} from "components/forms";
import React from "react";

const RegisterPage = () => {
    return (
        <FormWrapper>
            <RegisterForm />
        </FormWrapper>
    );
};
export default RegisterPage;
