import {useCookies} from "react-cookie";
import moment from "moment";
import usePromiseState from "./usePromiseState";
import {useCallback, useRef} from "react";

const useAuthentication = (defaultAuth) => {
    const [auth, setAuth] = usePromiseState(defaultAuth);
    const [, setCookie] = useCookies([
        "access_token",
        "refresh_token",
        "is_logged_in",
        "account_type",
    ]);
    const persistent_cookies = ["refresh_token"];
    const setAuthCookies = useCallback(
        (data) => {
            return new Promise((resolve) => {
                let config = {
                    path: "/",
                };
                for (const key in data) {
                    if (persistent_cookies.includes(key)) {
                        config.expires = moment().add(30, "days").toDate();
                    }
                    setCookie(key, data[key], config);
                }
                setAuth(data)
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {});
            });
        },
        [auth]
    );

    return {auth, setAuth: setAuthCookies};
};

export default useAuthentication;
