import {ResetPasswordForm, FormWrapper} from "components/forms";
import React from "react";

const ResetPasswordPage = () => {
    return (
        <FormWrapper>
            <ResetPasswordForm />
        </FormWrapper>
    );
};

export default ResetPasswordPage;
