import React, {useEffect, useState} from "react";
import {useRequest, useValidation} from "hooks";
import {adminPayPalrequest} from "requests";
import {SpinnerButton, ValidatedInput} from "utils";
import {paypalFormValidations} from "./validations";
import {toast} from "react-toastify";

const defaultSettings = {
    api_key: "",
    webhook_secret: "",
    public_key: "",
};

const EditPayPalForm = () => {
    const [settings, setSettings] = useState(defaultSettings);
    const {errors, validate} = useValidation(paypalFormValidations);
    const [request, isRequesting] = useRequest(adminPayPalrequest);

    useEffect(() => {
        request({type: "GET"})
            .then((r) => {
                setSettings(r.data.data);
            })
            .catch((e) => {});
    }, []);

    const onChangeHandler = ({target: {name, value}}) => {
        const newSettings = {...settings, [name]: value};
        setSettings(newSettings);
        validate(newSettings, name).catch((e) => {});
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        validate(settings)
            .then((r) => {
                request(settings)
                    .then((r) => {
                        toast.success("Saved stripe credentials successfully");
                    })
                    .catch((e) => {
                        toast.error("Saving failed");
                    });
            })
            .catch((e) => {});
    };

    return (
        <form className="user" onSubmit={onSubmitHandler}>
            <div className="form-group">
                <div className="form-row">
                    <div className="form-col col-12 col-md-4 mb-3 mb-md-0">
                        <ValidatedInput
                            type="text"
                            name="api_key"
                            className="form-control bg-light"
                            placeholder="Stripe api key"
                            label="Stripe api key"
                            value={settings.api_key}
                            onChange={onChangeHandler}
                            error={errors.api_key}
                        />
                    </div>
                    <div className="form-col col-12 col-md-4  mb-3 mb-md-0">
                        <ValidatedInput
                            type="text"
                            name="webhook_secret"
                            className="form-control bg-light"
                            placeholder="Stripe webhook secret"
                            label="Stripe webhook secret"
                            value={settings.webhook_secret}
                            error={errors.webhook_secret}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="form-col col-12 col-md-4">
                        <ValidatedInput
                            type="text"
                            name="public_key"
                            className="form-control bg-light"
                            placeholder="Public key"
                            label="Public key"
                            value={settings.public_key}
                            error={errors.public_key}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <SpinnerButton
                    className="btn btn-primary btn-block"
                    loading={isRequesting}
                >
                    Save
                </SpinnerButton>
            </div>
        </form>
    );
};
export default EditPayPalForm;
