import {FormWrapper, LoginForm} from "components/forms";
import React from "react";

const LoginPage = (props) => {
    return (
        <FormWrapper>
            <LoginForm {...props} />
        </FormWrapper>
    );
};

export default LoginPage;
