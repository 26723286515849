import React, {useEffect} from "react";
import AuthContext from "./AuthContext";
import {useAuthentication} from "hooks";
import {useCookies} from "react-cookie";
import moment from "moment";

const AuthContextProvider = ({children}) => {
    const [cookies, setCookie] = useCookies([
        "access_token",
        "refresh_token",
        "is_logged_in",
        "account_type",
        "privileges",
    ]);

    const defaultAuthState = {
        access_token: cookies.access_token || null,
        refresh_token: cookies.refresh_token || null,
        is_logged_in: cookies.is_logged_in === "true" || false,
        account_type: cookies.account_type || false,
        privileges: cookies.privileges || [],
    };
    const authObj = useAuthentication(defaultAuthState);
    const {auth} = authObj;

    useEffect(() => {
        const persistent_cookies = ["refresh_token"];
        for (const key in auth) {
            let config = {
                path: "/",
            };
            if (persistent_cookies.includes(key)) {
                config.expires = moment().add(30, "days").toDate();
            }
            setCookie(key, auth[key], config);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);
    return <AuthContext.Provider value={authObj}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
