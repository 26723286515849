export default function request(axios, data) {
    let newData = data;
    let _type = "";
    if (data) {
        const {type, ...rest} = data;
        _type = type;
        newData = rest;
    }
    switch (_type) {
        case "GET":
            return axios.get("/billing/stripe/credentials", data);
        default:
            return axios.post("/billing/stripe/credentials", data);
    }
}
