import React, {useState, useEffect} from "react";
import "styles/dashboard.css";
import {Img, SpinnerButton} from "utils";
import {FormattedMessage} from "react-intl";
import {BiLogInCircle} from "react-icons/bi";
import {useInterval, useRequest} from "hooks";
import {clientGetPXlink} from "requests";
import {clientSubInfo} from "requests";
import {DashboardCard} from "components/cards";
import {ImSpinner3} from "react-icons/im";

const ClientDashboardPage = ({success, cancel}) => {
    const url = "/px.jpeg";
    const [request, noData] = useRequest(clientSubInfo);
    const [PointxRequest, isRequestingPX] = useRequest(clientGetPXlink);
    const [active, setActive] = useState(false);
    const [running, setRunning] = useState(false);
    const [isRequesting, setRequesting] = useState(true);
    const [data, setData] = useState({});
    const [refresh, setRefresh] = useState(0);

    useInterval(() => {
        if (!active)
            request()
                .then((r) => {
                    const sub = r.data.data.subscriptions[0];
                    setActive(sub.pointx_active);
                    setRunning(sub.is_pointx_running);
                })
                .catch((e) => {});
    }, [5000]);
    useEffect(() => {
        setRequesting(true);
        request()
            .then((r) => {
                const sub = r.data.data.subscriptions[0];
                setData(sub);
                setActive(sub.pointx_active);
                setRunning(sub.is_pointx_running);
            })
            .catch((e) => {})
            .finally(() => {
                setRequesting(false);
            });
    }, [refresh]);

    const goToPointx = (e) => {
        e.preventDefault();
        if (active && !isRequesting && running)
            PointxRequest({subscription_id: data.id})
                .then((r) => {
                    window.open("http://" + r.data.data.link, "_blank");
                })
                .catch((e) => {});
    };
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        {success ? (
                            <div class="alert alert-success mt-2" role="alert">
                                <FormattedMessage
                                    id="payment_success"
                                    defaultMessage="Your payment is successful!"
                                />
                            </div>
                        ) : cancel ? (
                            <div class="alert alert-danger mt-2" role="alert">
                                <FormattedMessage
                                    id="payment_cancel"
                                    defaultMessage="Your payment failed!"
                                />
                            </div>
                        ) : null}
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <DashboardCard
                                    isRequesting={isRequesting}
                                    data={data}
                                    refresh={setRefresh}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="d-flex flex-column h-100 pb-4">
                                    <div
                                        className="img-container"
                                        onClick={goToPointx}
                                    >
                                        <Img
                                            className="img"
                                            src={url}
                                            alt="bg"
                                        />
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center cont">
                                        <SpinnerButton
                                            className={`btn btn-primary w-100 ${
                                                noData ? "mt-3" : ""
                                            }`}
                                            onClick={goToPointx}
                                            loading={isRequestingPX}
                                            disabled={
                                                !active ||
                                                isRequesting ||
                                                !running
                                            }
                                        >
                                            {active ? (
                                                <>
                                                    <BiLogInCircle className="mr-1" />
                                                    <FormattedMessage
                                                        id="go_to_px"
                                                        defaultMessage="Go to PointX"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <ImSpinner3 className="fa-spin mr-1" />
                                                    <FormattedMessage
                                                        id="setting_px"
                                                        defaultMessage="Setting up your system"
                                                    />
                                                </>
                                            )}
                                        </SpinnerButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientDashboardPage;
