import {useEffect, useState} from "react";
import {googleKeyRequest} from "requests";
import useRequest from "./useRequest";

const useGoogle = () => {
    const [google, setGoogle] = useState();
    const [request, requesting] = useRequest(googleKeyRequest);

    useEffect(() => {
        request()
            .then((r) => {
                const g = r.data.data.key;
                setGoogle(g);
            })
            .catch(() => {});
    }, []);
    return [google, requesting];
};

export default useGoogle;
