import React, {useState} from "react";
import {dictionaryList, languageOptions} from "./dictionaryList";
import LanguageContext from "./LanguageContext";

const LanguageProvider = ({children, locale = "en"}) => {
    const [userLanguage, setUserLanguage] = useState(locale);

    const provider = {
        userLanguage,
        dictionary: dictionaryList[userLanguage],
        userLanguageChange: (selected) => {
            const newLanguage = languageOptions[selected] ? selected : "en";
            setUserLanguage(newLanguage);
            window.localStorage.setItem("lang", newLanguage);
        },
    };

    return (
        <LanguageContext.Provider value={provider}>
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageProvider;
