import React from "react";
import {EditPayPalForm, EditSTMPForm} from "components/forms";
import {EDIT_STRIPE_KEYS, UPDATE_SMTP_DETAILS} from "config/privilege";
import {usePrivilege} from "hooks";

const AdminSettingsPage = () => {
    const {canAccess} = usePrivilege();
    return (
        <>
            <div className="container-fluid">
                {canAccess(UPDATE_SMTP_DETAILS) && (
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex">
                            <h6 className="m-0 font-weight-bold text-primary">
                                SMTP credentials
                            </h6>
                        </div>
                        <div className="m-3 mb-4">
                            <EditSTMPForm />
                        </div>
                    </div>
                )}
            </div>
            <hr />
            <div className="container-fluid">
                {canAccess(EDIT_STRIPE_KEYS) && (
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex">
                            <h6 className="m-0 font-weight-bold text-primary">
                                Stripe credentials
                            </h6>
                        </div>
                        <div className="m-3 mb-4">
                            <EditPayPalForm />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default AdminSettingsPage;
