import {AddTransactionsModel} from "components/modals";
import {AdminTransactionsTable} from "components/tables";
import {usePrivilege} from "hooks";
import React, {useState} from "react";
import {CREATE_MANUAL_TRANSACTIONS} from "config/privilege";
import {FiFilter} from "react-icons/fi";

const AdminTransactionsPage = () => {
    const {canAccess} = usePrivilege();
    const [fetch, triggerFetch] = useState();
    return (
        <>
            <div className="container-fluid">
                {/* <h1 className="h3 mb-2 text-gray-800">Transactions</h1> */}

                <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h6 className="m-0 font-weight-bold text-primary">
                            Transactions
                        </h6>
                        <div className="ml-auto d-flex">
                            <div className="mr-2">
                                {canAccess(CREATE_MANUAL_TRANSACTIONS) && (
                                    <AddTransactionsModel
                                        triggerFetch={triggerFetch}
                                    />
                                )}
                            </div>
                            <a
                                data-toggle="modal"
                                data-target="#filter-transaction"
                                href="/"
                                className="btn btn-info btn-icon-split d-none d-sm-inline-block p-0"
                                onClick={(e) => e.preventDefault()}
                            >
                                <span className="icon">
                                    <FiFilter />
                                </span>
                                <span className="text">Filter</span>
                            </a>
                            <a
                                data-toggle="modal"
                                data-target="#filter-transaction"
                                className="btn-circle btn-info d-inline-flex d-sm-none"
                                href="/"
                                onClick={(e) => e.preventDefault()}
                            >
                                <FiFilter />
                            </a>
                        </div>
                    </div>
                    <div className="card-body">
                        <AdminTransactionsTable fetch={fetch} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminTransactionsPage;
