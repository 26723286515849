import React, {useContext, useState} from "react";
import {useRequest, useValidation} from "hooks";
import {loginRequest} from "requests";
import {SpinnerButton, ValidatedInput} from "utils";
import {adminLoginFormValidations} from "./validations";
import {AuthContext} from "context";
import {useHistory} from "react-router-dom";
import {adminDashboard} from "routes/routes";

const AdminLoginForm = () => {
    const [user, setUser] = useState({username: "", password: ""});
    const {errors, validate, addErrors} = useValidation(adminLoginFormValidations);
    const [request, isRequesting] = useRequest(loginRequest);
    const {setAuth} = useContext(AuthContext);
    const history = useHistory();

    const onChangeHandler = ({target: {name, value}}) => {
        const newUser = {...user, [name]: value};
        setUser(newUser);
        validate(newUser, name).catch((e) => {});
    };
    const checkboxHandler = ({target: {name, checked}}) => {
        const newUser = {...user, [name]: checked};
        setUser(newUser);
    };
    const onSubmitHandler = (e) => {
        e.preventDefault();
        validate(user)
            .then((r) => {
                request({...user, type: "ADMIN"})
                    .then(({data}) => {
                        setAuth({
                            access_token: data.data.access_token,
                            refresh_token: data.data.remember_token,
                            is_logged_in: true,
                            privileges: data.data.privileges,
                            account_type: "ADMIN",
                        });
                        history.push(adminDashboard);
                    })
                    .catch((e) => {
                        console.log("error");
                        addErrors({
                            email: ["Wrong username/password"],
                            password: ["Wrong username/password"],
                        });
                    });
            })
            .catch((e) => {});
    };
    return (
        <form className="user" onSubmit={onSubmitHandler}>
            <div className="form-group">
                <ValidatedInput
                    type="text"
                    name="username"
                    className="form-control form-control-user"
                    placeholder="username"
                    value={user.username}
                    onChange={onChangeHandler}
                    error={errors.username}
                />
            </div>
            <div className="form-group">
                <ValidatedInput
                    type="password"
                    name="password"
                    className="form-control form-control-user"
                    placeholder="Password"
                    value={user.password}
                    error={errors.password}
                    onChange={onChangeHandler}
                />
            </div>
            {/* <div className="form-group">
                <div className="custom-control custom-checkbox small">
                    <ValidatedInput
                        type="checkbox"
                        className="custom-control-input"
                        id="remember_me"
                        name="remember_me"
                        checked={user.remember_me}
                        error={errors.remember_me}
                        onChange={checkboxHandler}
                    >
                        <label
                            className="custom-control-label"
                            htmlFor="remember_me"
                        >
                            Remember Me
                        </label>
                    </ValidatedInput>
                </div>
            </div> */}
            <SpinnerButton
                className="btn btn-primary btn-user btn-block"
                loading={isRequesting}
            >
                Login
            </SpinnerButton>
        </form>
    );
};
export default AdminLoginForm;
