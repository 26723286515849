import React, {useEffect, useState} from "react";
import {useRequest, useValidation} from "hooks";
import {adminClientRequests} from "requests";
import {countries, SingleSelect, SpinnerButton, ValidatedInput} from "utils";
import {editClientFormValidations} from "./validations";
import {toast} from "react-toastify";
import $ from "jquery";

const defaultClient = {
    first_name: "",
    last_name: "",
    company: "",
    vat: "",
    email: "",
    username: "",
    phone: "",
    country_code: "",
    // is_active: "",
    registeration_plan: "",
    address_line_1: "",
    address_line_2: "",
    password: null,
    password_confirm: null,
    postal_code: "",
};
const keys = [
    "first_name",
    "last_name",
    "company",
    "vat",
    "email",
    "username",
    "phone",
    "address_line_1",
    "address_line_2",
    "password",
    "password_confirm",
    "postal_code",
];
const labels = [
    "First name",
    "Last name",
    "Company",
    "VAT number",
    "Email",
    "Username",
    "Phone",
    "Address line 1",
    "Address line 2",
    "Password",
    "Password confirm",
    "Postal code",
];
const EditClientForm = ({client: props_client, triggerFetch}) => {
    const [client, setClient] = useState(defaultClient);
    const [sub, setSub] = useState({});
    const {errors, validate} = useValidation(editClientFormValidations);
    const [request, isRequesting] = useRequest(adminClientRequests);

    useEffect(() => {
        if (props_client && props_client.name) {
            setClient(props_client);
            request({type: "SUB", client_username: props_client.username})
                .then((r) => {
                    const s = r.data.data.subscriptions[0];
                    setSub(s);
                })
                .catch(() => {});
        }
    }, [props_client]);

    const onChangeHandler = ({target: {name, value}}) => {
        const newClient = {...client, [name]: value};
        setClient(newClient);
        validate(newClient, name)
            .catch(() => {})
            .finally(() => {
            });
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        const payload = {
            client_username: client.username,
            profile_information: {
                name: client.name,
                phone: client.phone,
                address: {
                    line_1: client.address_line_1,
                    line_2: client.address_line_2,
                    country_code: client.country_code,
                    postal_code: client.postal_code,
                },
            }, // Profile information object (name, phone, address)
            company_information: {
                company_name: client.company,
                vat_number: client.vat,
            }, // Company information object (company_name, vat_number)
            password: client.password, // leave this as null if password is not changed
            email: client.email, // Leave this as null if email is not changed
        };
        validate(client)
            .then(() => {
                request({...payload, type: "EDIT"})
                    .then(() => {
                        toast.success("Saved client successfully");
                        triggerFetch(Math.random());
                        $("#edit-registered-client").modal("hide");
                        $("body").removeClass("modal-open");
                        $(".modal-backdrop").remove();
                    })
                    .catch(() => {
                        toast.error("Saving failed");
                    });
            })
            .catch(() => {});
    };
    const callback = (item, i) => {
        return (
            <option value={item.value} key={i}>
                {item.name}
            </option>
        );
    };
    return (
        <form onSubmit={onSubmitHandler}>
            <div className="form-row input-group input-group-md-down-break justify-content-center mb-2">
                {keys.map((key, i) => (
                    <div key={key} className="col-12 col-sm-6">
                        <ValidatedInput
                            type={key.includes("pass") ? "password" : "text"}
                            name={key}
                            className="form-control"
                            placeholder={labels[i]}
                            label={labels[i]}
                            value={client[key]}
                            onChange={onChangeHandler}
                            error={errors[key]}
                        />
                    </div>
                ))}
                <div className="col-12">
                    <SingleSelect
                        type="text"
                        name="country_code"
                        className="form-control"
                        placeholder="Country"
                        label="Country"
                        value={client.country_code}
                        error={errors.country_code}
                        onChange={onChangeHandler}
                        options={countries}
                        callback={callback}
                    />
                </div>
            </div>
            <SpinnerButton
                className="btn btn-primary btn-block"
                loading={isRequesting}
            >
                Save
            </SpinnerButton>
            <hr />
            {sub && sub.pointx_metadata && (
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Key</th>
                            <th scope="col">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(sub.pointx_metadata).map((key) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>{sub.pointx_metadata[key]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </form>
    );
};
export default EditClientForm;
