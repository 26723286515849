import {FormWrapper, AdminLoginForm} from "components/forms";
import React from "react";

const AdminLoginPage = () => {
    return (
        <div id="wrapper">
            <div className="container">
                <div className="row justify-content-center a">
                    <div className="col-xl-10 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6 p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">
                                                Welcome Back!
                                            </h1>
                                            <AdminLoginForm />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminLoginPage;
