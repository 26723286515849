import React, {useState, useEffect} from "react";
import {useRequest} from "hooks";
import {adminClientRequests, adminTemporalAccess} from "requests";
import {AiFillEdit, AiFillDelete, AiOutlineLogin} from "react-icons/ai";
import {GrPlan} from "react-icons/gr";
import {confirm, SpinnerButton} from "utils";
import {codes} from "utils/countries";
import Table from "./Table";
import moment from "moment";
import $ from "jquery";

const RegisteredClientsTable = ({
    setClient = () => {},
    canEdit,
    canDelete,
    canAccess,
    fetch,
}) => {
    $("[data-toggle=tooltip]").tooltip();
    const Actions = (index) => {
        const [request, isRequesting] = useRequest(adminClientRequests);
        const [requestPXAccess] = useRequest(adminTemporalAccess);
        const row = data[index];
        const access = () => {
            request({type: "SUB", client_username: row.username})
                .then((r) => {
                    let subscription_id = r.data.data.subscriptions[0].id;
                    return requestPXAccess({subscription_id});
                })
                .then((r) => {
                    window.open("http://" + r.data.data.link, "_blank");
                })
                .catch((e) => {});
        };
        return (
            <div className="d-flex mx-2 py-2">
                {canAccess && (
                    <SpinnerButton
                        type="button"
                        className="btn btn-primary mr-1"
                        onClick={access}
                        loading={isRequesting}
                    >
                        <AiOutlineLogin />
                    </SpinnerButton>
                )}
                {canEdit && (
                    <button
                        type="button"
                        className="btn btn-info mr-1"
                        data-toggle="modal"
                        data-target="#edit-registered-client"
                        onClick={(e) => setClient(row)}
                    >
                        <AiFillEdit />
                    </button>
                )}
                {canEdit && (
                    <button
                        type="button"
                        className="btn btn-outline-warning mr-1"
                        data-toggle="modal"
                        data-target="#edit-client-subscription"
                        onClick={(e) => setClient(row)}
                    >
                        <GrPlan />
                    </button>
                )}
                {canDelete && (
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={(e) => confirmDelete(row)}
                    >
                        <AiFillDelete />
                    </button>
                )}
            </div>
        );
    };
    const confirmDelete = (row) =>
        confirm({
            message: "Are you sure you want to delete this client?",
            onConfirm: () => deleteSub(row),
        });
    const deleteSub = (row) => {
        request({...row, type: "DELETE"})
            .then((r) => {
                fetchData();
            })
            .catch((e) => {});
    };

    const columns = [
        {
            label: "Client name",
            name: "name",
            options: {sort: true},
        },
        {
            label: "Company",
            name: "company",
            options: {sort: true},
        },
        {
            label: "VAT number",
            name: "vat",
            options: {sort: true},
        },
        {
            label: "Email",
            name: "email",
            options: {
                sort: true,
                customBodyRenderLite: (index) => {
                    const {email, unverified_email} = data[index];
                    return (
                        <div
                            className="d-block text-truncate"
                            data-toggle="tooltip"
                            title={email ? email : unverified_email}
                            data-placement="top"
                        >
                            {email ? email : unverified_email}
                        </div>
                    );
                },
            },
        },
        {
            label: "Status",
            name: "verified",
            options: {
                sort: true,
                customBodyRenderLite: (row) => (
                    <div>
                        {data[row].email ? (
                            <span className="badge badge-pill badge-success mr-1 mb-1">
                                verified
                            </span>
                        ) : (
                            <span className="badge badge-pill badge-danger mr-1 mb-1">
                                unverified
                            </span>
                        )}
                    </div>
                ),
            },
        },
        {
            label: "Username",
            name: "username",
            options: {sort: true},
        },
        {
            label: "Phone",
            name: "phone",
            options: {sort: true},
        },
        {
            label: "Country",
            name: "country",
            options: {sort: true},
        },
        // {
        //     name: "Active/In-active",
        //     selector: "is_active",
        //     sort: true,
        //     customBodyRenderLite: (row) => <div>{row.is_active ? "Active" : "Not active"}</div>,
        // },
        // {
        //     name: "Registration plan",
        //     selector: "registeration_plan",
        //     sort: true,
        // },
        {
            label: "Last login",
            name: "last_login",
            options: {
                sort: false,
                customBodyRenderLite: (row) =>
                    moment(data[row].last_login).format(
                        "DD/MM/YYYY [at] hh:mm a"
                    ),
            },
        },
        {
            label: "Actions",
            name: "",
            options: {
                sort: false,
                customBodyRenderLite:
                    canAccess || canDelete || canEdit
                        ? Actions
                        : () => "No actions",
            },
        },
    ];

    const [data, setData] = useState([
        {
            username: "username",
            name: "name",
            email: "email",
            phone: "phone",
            company: "company_name",
            vat: "vat_number",
            address_line_1: "line_1",
            address_line_2: "line_2",
            postal_code: "postal_code",
            country_code: "EG",
            country: codes["EG"],
        },
    ]);

    const [request, requesting] = useRequest(adminClientRequests);

    const fetchData = () => {
        request()
            .then(({data}) => {
                let clients = data.data.clients;
                let adapterClients = [];
                clients.forEach((client) => {
                    const info = client.profile_information || {};
                    const address = info.address || {};
                    const company_info = client.company_information || {};
                    const parsedClient = {
                        username: client.username,
                        name: info.first_name + " " + info.last_name,
                        first_name: info.first_name,
                        last_name: info.last_name,
                        email: client.email,
                        last_login: client.last_login,
                        unverified_email: client.unverified_email,
                        verified: client.unverified_email ? true : false,
                        phone: info.phone,
                        company: company_info.company_name,
                        vat: company_info.vat_number,
                        address_line_1: address.line_1,
                        address_line_2: address.line_2,
                        postal_code: address.postal_code,
                        country_code: address.country_code,
                        country: codes[address.country_code],
                    };
                    adapterClients.push(parsedClient);
                });
                setData(adapterClients);
            })
            .catch((e) => {});
    };
    useEffect(fetchData, [fetch]);

    return (
        <Table
            columns={columns}
            data={data}
            loading={requesting}
            options={{filter: false}}
        />
    );
};
export default RegisteredClientsTable;
