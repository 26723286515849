import React, {useContext} from "react";
import {Link, useLocation} from "react-router-dom";
import {
    forgotPasswordRoute,
    loginRoute,
    privacyPolicyRoute,
    registerRoute,
    resetPasswordRoute,
    termsRoute,
    verifiedRoute,
} from "routes/routes";
import "./FormWrapper.css";
import {FormattedMessage, useIntl} from "react-intl";
import {LanguageContext, languageOptions} from "lang";
const FormTitle = () => {
    const location = useLocation();
	if(location.pathname.split('/')[1] === 'reset-password'){
        return (
            <FormattedMessage
                id="resetPassword_route"
                defaultMessage="Reset password"
            />
        );
    }
    switch (location.pathname) {
        case loginRoute:
            return (
                <FormattedMessage
                    id="login_route"
                    defaultMessage="Login Area"
                />
            );
        case registerRoute:
            return (
                <FormattedMessage
                    id="register_route"
                    defaultMessage="Register Area"
                />
            );
        case forgotPasswordRoute:
            return (
                <FormattedMessage
                    id="forgotPassword_route"
                    defaultMessage="Forgot Password"
                />
            );
        case resetPasswordRoute:
            return (
                <FormattedMessage
                    id="resetPassword_route"
                    defaultMessage="Reset password"
                />
            );
        case verifiedRoute:
            return (
                <FormattedMessage
                    id="verified_route"
                    defaultMessage="Account verified"
                />
            );
        default:
            return (
                <FormattedMessage
                    id="login_route"
                    defaultMessage="Login Area"
                />
            );
    }
};
const FormWrapper = ({children}) => {
    const {formatMessage} = useIntl();

    return (
        <div
            className="container-fluid"
            style={{
                backgroundImage: "url(/login_wallpaper.jpg)",
                minHeight: "100%",
                backgroundSize: "cover",
                backgroundPosition: "50%",
                backgroundRepeat: "no-repeat",
            }}
        >
            <div className="row min-vh-100">
                <div className="col-sm-10 offset-sm-1 col-md-4 offset-md-1 bg-white d-flex flex-column">
                    <div
                        className="row"
                        style={{borderBottom: "1px solid #eee"}}
                    >
                        <div className="col-12">
                            <div className="d-flex justify-content-center aligen-items-center">
                                <img
                                    src="/logo.png"
                                    height="100"
                                    width="100"
                                    alt="logo"
                                    style={{
                                        marginTop: "40px",
                                        marginBottom: "25px",
                                    }}
                                />
                            </div>
                            <h4
                                className="w-100 text-center"
                                style={{color: "#282828", marginBottom: "25px"}}
                            >
                                PointX CM Cloud
                            </h4>
                        </div>
                    </div>
                    <div className="row my-auto">
                        <div className="col-12">
                            <div className="m-2 form-container d-block py-4">
                                <div
                                    className="text-center pb-4"
                                    style={{color: "#282828"}}
                                >
                                    <h3>
                                        <FormTitle />
                                    </h3>
                                </div>
                                {children}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="d-flex m-2"
                                style={{justifyContent: "space-between"}}
                            >
                                <div className="text-center">
                                    <Languages />
                                </div>
                                <div className="text-center">
                                    <Link to={termsRoute} target="_blank">
                                        {formatMessage({
                                            id: "terms_of_service",
                                            defaultMessage: "Terms of service",
                                        })}
                                    </Link>
                                </div>
                                <div className="text-center">
                                    <Link
                                        to={privacyPolicyRoute}
                                        target="_blank"
                                    >
                                        {formatMessage({
                                            id: "privacy_policy",
                                            defaultMessage: "Privacy policy",
                                        })}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Languages = () => {
    const {userLanguage, userLanguageChange = () => {}} = useContext(
        LanguageContext
    );
    const handleLanguageChange = (id, e) => {
        e.preventDefault();
        userLanguageChange(id);
    };
    return (
        <div className="dropup">
            <div className="arrow-up pointer p-0">
                {languageOptions[userLanguage].lang}
            </div>
            <div className="dropup-content">
                {Object.entries(languageOptions).map(
                    ([id, {lang, country}]) => (
                        <a
                            className="dropdown-item"
                            href="/"
                            key={id}
                            onClick={(e) => handleLanguageChange(id, e)}
                        >
                            <img
                                className="dropdown-item-image rounded-circle p-0 mr-2"
                                src={`/flags/${country}.svg`}
                                alt={`${country} Flag`}
                            />
                            <span className="text-truncate justify-content-center align-items-center">
                                {lang}
                            </span>
                        </a>
                    )
                )}
            </div>
        </div>
    );
};

export default FormWrapper;
