export const SUPER_ADMIN = "SUPER_ADMIN";
export const CREATE_SUBSCRIPTION_PLANS = "CREATE_SUBSCRIPTION_PLANS";
export const EDIT_STRIPE_KEYS = "EDIT_STRIPE_KEYS";
export const CREATE_MANUAL_TRANSACTIONS = "CREATE_MANUAL_TRANSACTIONS";
export const LIST_TRANSACTIONS = "LIST_TRANSACTIONS";
export const LIST_CLIENTS = "LIST_CLIENTS";
export const EDIT_CLIENTS = "EDIT_CLIENTS";
export const DELETE_CLIENTS = "DELETE_CLIENTS";
export const UPDATE_SMTP_DETAILS = "UPDATE_SMTP_DETAILS";
export const ACCESS_POINTX = "ACCESS_POINTX";
export const DELETE_SUBSCRIPTION_PLAN = "DELETE_SUBSCRIPTION_PLAN";
export const EDIT_SUBSCRIPTION_PLAN = "EDIT_SUBSCRIPTION_PLAN";
export const LIST_SUBSCRIPTIONS = "LIST_SUBSCRIPTIONS";
export const VIEW_LOGS = "VIEW_LOGS";
export const privileges = {
    SUPER_ADMIN: "super admin",
    CREATE_SUBSCRIPTION_PLANS: "creat plan",
    EDIT_STRIPE_KEYS: "edit stripe",
    CREATE_MANUAL_TRANSACTIONS: "create transaction",
    LIST_TRANSACTIONS: "list transactions",
    LIST_CLIENTS: "list clients",
    EDIT_CLIENTS: "edit clients",
    DELETE_CLIENTS: "delete clients",
    UPDATE_SMTP_DETAILS: "edit STMP",
    ACCESS_POINTX: "access point-x",
    DELETE_SUBSCRIPTION_PLAN: "delete plan",
    EDIT_SUBSCRIPTION_PLAN: "edit plan",
    LIST_SUBSCRIPTIONS: "list plans",
    VIEW_LOGS: "view logs",
};
export const privilegesList = [
    {label: "super admin", value: "SUPER_ADMIN"},
    {label: "creat plan", value: "CREATE_SUBSCRIPTION_PLANS"},
    {label: "edit stripe", value: "EDIT_STRIPE_KEYS"},
    {label: "create transaction", value: "CREATE_MANUAL_TRANSACTIONS"},
    {label: "list transactions", value: "LIST_TRANSACTIONS"},
    {label: "list clients", value: "LIST_CLIENTS"},
    {label: "edit clients", value: "EDIT_CLIENTS"},
    {label: "delete clients", value: "DELETE_CLIENTS"},
    {label: "edit STMP", value: "UPDATE_SMTP_DETAILS"},
    {label: "access point-x", value: "ACCESS_POINTX"},
    {label: "delete plan", value: "DELETE_SUBSCRIPTION_PLAN"},
    {label: "edit plan", value: "EDIT_SUBSCRIPTION_PLAN"},
    {label: "list plans", value: "LIST_SUBSCRIPTIONS"},
    {label: "view logs", value: "VIEW_LOGS"},
];
