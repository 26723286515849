export const loginRoute = "/login";
export const adminLoginRoute = "/admin_zvtx/login";
export const registerRoute = "/register";
export const homeRoute = "/home";
export const adminRoute = "/admin_zvtx";
export const adminDashboard = "/admin_zvtx/dashboard";
export const adminManagers = "/admin_zvtx/dashboard/managers";
export const adminSubscriptionPlans = "/admin_zvtx/dashboard/subscription-plans";
export const adminClients = "/admin_zvtx/dashboard/clients";
export const adminTransactions = "/admin_zvtx/dashboard/transactions";
export const adminPointXVersions = "/admin_zvtx/dashboard/PointX-versions";
export const adminLogsView = "/admin_zvtx/dashboard/logs-view";
export const adminGlobalSettings = "/admin_zvtx/dashboard/global-settings";
export const userRoute = "/user";
export const forgotPasswordRoute = "/forgot-password";
export const termsRoute = "/terms";
export const privacyPolicyRoute = "/privacy-policy";
export const resetPasswordRoute = "/reset-password";
export const verifiedRoute = "/login/account-verified";
export const editProfileRoute = "/dashboard/edit-profile";
export const editSettings = "/dashboard/settings";
export const editSecurity = "/dashboard/security";
export const transactionsRoute = "/dashboard/transactions";
export const pricingPlansRoute = "/dashboard/plans";
export const redirectToPXRoute = "/dashboard/point-x";
export const dashboardRoute = "/dashboard";
export const root = "/";
export const paymentSuccessRoute = redirectToPXRoute + "/success";
export const paymentCancelRoute = redirectToPXRoute + "/cancel";
export const smartLogin =  "/smart-login/:identifier";
