import {AuthContext} from "context";
import {useContext, useEffect, useState} from "react";
// import {toast} from "react-toastify";
import {getClientInfo} from "requests";
import useRequest from "./useRequest";

const useClient = () => {
    const [request, requesting] = useRequest(getClientInfo);
    const [savedClient, setClient] = useState({});
    const {auth} = useContext(AuthContext);
    useEffect(() => {
        if (auth.account_type === "CLIENT")
            request()
                .then((r) => {
                    const client = r.data.data.client;
                    const info = client.profile_information || {};
                    const company_info = client.company_information || {};
                    const address = info.address || {};
                    const loadedUser = {
                        username: client.username,
                        email: client.email,
                        first_name: info.first_name,
                        last_name: info.last_name,
                        phone: info.phone,
                        company_name: company_info.company_name,
                        eu_vat: company_info.vat_number,
                        address_line_1: address.line_1,
                        address_line_2: address.line_2,
                        postal_code: address.postal_code,
                        country_code: address.country_code,
                    };
                    setClient(loadedUser);
                })
                .catch((e) => {
                    // toast.error("Failed to load client");
                });
    }, []);
    return [savedClient, requesting];
};

export default useClient;
