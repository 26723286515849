import {useRequest, useValidation} from "hooks";
import React, {useState} from "react";
import {forgotPasswordRequest} from "requests";
import {Img, SpinnerButton, ValidatedInput} from "utils";
import {forgotPasswordFormValidations} from "./validations";
import {useIntl} from "react-intl";
import SwipeableViews from "react-swipeable-views";
import {toast} from "react-toastify";

const ForgotPasswordForm = () => {
    const [user, setUser] = useState({email: ""});

    const {errors, validate, hasErrors} = useValidation(
        forgotPasswordFormValidations
    );
    const [request, isRequesting] = useRequest(forgotPasswordRequest);
    const {formatMessage} = useIntl();
    const [activeStep, setActiveStep] = useState(0);
    const handleChange = (index) => (e) => setActiveStep(index);

    const onChangeHandler = ({target: {name, value}}) => {
        const newUser = {...user, [name]: value};
        setUser(newUser);
        validate(newUser, name).catch((e) => {});
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        validate(user)
            .then((r) => {
                request(user)
                    .then((r) => {
                        setActiveStep(1);
                    })
                    .catch((e) => {
                        toast.error(
                            formatMessage({
                                id: "forgot_password_no_email",
                                defaultMessage: "Incorrect email address",
                            })
                        );
                    });
            })
            .catch((e) => {});
    };
    return (
        <SwipeableViews index={activeStep} onChangeIndex={handleChange}>
            <div className="mx-2">
                <form onSubmit={onSubmitHandler}>
                    <div className="form-group">
                        <ValidatedInput
                            type="text"
                            name="email"
                            className="form-control"
                            placeholder={formatMessage({
                                id: "email_address",
                                defaultMessage: "Email Address",
                            })}
                            label={formatMessage({
                                id: "email_address",
                                defaultMessage: "Email Address",
                            })}
                            value={user.email}
                            onChange={onChangeHandler}
                            error={errors.email}
                        />
                    </div>

                    <SpinnerButton
                        className="btn btn-primary btn-block"
                        loading={isRequesting}
                    >
                        {formatMessage({
                            id: "reset_password",
                            defaultMessage: "Reset Password",
                        })}
                    </SpinnerButton>
                </form>
            </div>
            <div className="mx-2">
                <div className="d-block">
                    <Img src="/forgot_pass.svg" className="img-fluid" />
                </div>

                <h4 className="mt-4">
                    {formatMessage({
                        id: "reset_success",
                        defaultMessage: "Reset successful!",
                    })}
                    <br />{" "}
                    {formatMessage({
                        id: "check_email",
                        defaultMessage: "Please check your email!",
                    })}
                </h4>
                <br />
            </div>
        </SwipeableViews>
    );
};

export default ForgotPasswordForm;
