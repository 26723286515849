import React, {useContext} from "react";
import {BrowserRouter, Link, Route, Switch} from "react-router-dom";
import {AdminRoutes, UserRoutes} from "routes";
import * as r from "routes/routes";
import "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import {ToastContainer} from "react-toastify";
import {IntlProvider} from "react-intl";
import {dictionaryList, LanguageContext} from "lang";
import "react-toastify/dist/ReactToastify.css";

const Links = () => (
    <div>
        <Link to={r.homeRoute}> home </Link>
        <br />
        <Link to={r.loginRoute}> login </Link>
        <br />
        <Link to={r.registerRoute}> register </Link>
        <br />
        <Link to={r.forgotPasswordRoute}> forgot password </Link>
        <br />
        <Link to={`${r.resetPasswordRoute}/19090`}>reset password</Link>
        <br />
        <Link to={r.dashboardRoute}> dashboard </Link>
        <br />
        <Link to={r.editProfileRoute}> edit Profile </Link>
        <br />
        <Link to={r.transactionsRoute}> transactions </Link>
        <br />
        <Link to={r.pricingPlansRoute}> pricing Plans </Link>
        <br />
        <Link to={r.redirectToPXRoute}> redirect To PX </Link>
        <br />
        <Link to={r.root}> root </Link>
        <br />
        <Link to={r.adminLoginRoute}> admin login </Link>
        <br />
        <Link to={r.adminTransactions}> admin transactions </Link>
        <br />
    </div>
);

function App() {
    const {userLanguage} = useContext(LanguageContext);
    return (
        <IntlProvider
            defaultLocale="en"
            locale={userLanguage}
            messages={dictionaryList[userLanguage]}
        >
            <ToastContainer />
            <BrowserRouter>
                {/* <Links /> */}
                <Switch>
                    <Route path={r.adminRoute} component={AdminRoutes} />
                    <Route path="/" component={UserRoutes} />
                </Switch>
            </BrowserRouter>
        </IntlProvider>
    );
}

export default App;
