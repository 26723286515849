import React, {useState} from "react";
import {
    AddSubscriptionPlanModal,
    EditSubscriptionPlanModal,
} from "components/modals";
import {SubscriptionPlansTable} from "components/tables";
import {usePrivilege} from "hooks";
import {
    CREATE_SUBSCRIPTION_PLANS,
    DELETE_SUBSCRIPTION_PLAN,
    EDIT_SUBSCRIPTION_PLAN,
} from "config/privilege";

const AdminSubscriptionPlans = () => {
    const [plan, setPlan] = useState({});
    const [fetch, triggerFetch] = useState({});
    const {canAccess} = usePrivilege();

    return (
        <>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h6 className="m-0 font-weight-bold text-primary">
                            Subscription Plans
                        </h6>
                        <div className="ml-auto">
                            {canAccess(CREATE_SUBSCRIPTION_PLANS) && (
                                <AddSubscriptionPlanModal
                                    triggerFetch={triggerFetch}
                                />
                            )}
                            {canAccess(EDIT_SUBSCRIPTION_PLAN) && (
                                <EditSubscriptionPlanModal
                                    plan={plan}
                                    triggerFetch={triggerFetch}
                                />
                            )}
                        </div>
                    </div>
                    <div className="card-body">
                        <SubscriptionPlansTable
                            setPlan={setPlan}
                            canEdit={canAccess(EDIT_SUBSCRIPTION_PLAN)}
                            canDelete={canAccess(DELETE_SUBSCRIPTION_PLAN)}
                            fetch={fetch}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminSubscriptionPlans;
