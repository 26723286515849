import {Navbar} from "components/navbar";
import React, {useEffect, useState} from "react";
import {NavLink, useHistory} from "react-router-dom";
import {
    // editProfileRoute,
    transactionsRoute,
    pricingPlansRoute,
    redirectToPXRoute,
    editSettings,
    editSecurity,
    editProfileRoute,
} from "routes/routes";
import "./sidebar.css";
import {useIntl} from "react-intl";
import {
    AiOutlineArrowRight,
    AiOutlineArrowLeft,
    AiOutlinePoweroff,
} from "react-icons/ai";
import {GoDashboard} from "react-icons/go";
import {BiTransferAlt} from "react-icons/bi";
import {FiSettings} from "react-icons/fi";
import {MdSecurity} from "react-icons/md";
import {RiUserSettingsLine} from "react-icons/ri";
import {IoLayersOutline} from "react-icons/io5";
import {useLogout, useRequest} from "hooks";
import {logoutRequest} from "requests";
import {SpinnerButton} from "utils";

const SideBar = ({children}) => {
    const [toggle, setToggle] = useState(false);
    const {formatMessage} = useIntl();
    const {logout} = useLogout();
    const [request, requesting] = useRequest(logoutRequest);

    const toggleBarOnChange = () => {
        // Automatically close on phones
        if(window.innerWidth < 720){
            doToggle();
        }
    }

    useEffect(()=>{
        if(window.innerWidth > 720){
            doToggle();
        }
    }, []);

    const doToggle = () => {
        const element = document.getElementById("sidebar");
        if(typeof element !== "undefined"){
            element.classList.toggle("active");
        }
    };

    useEffect(() => {
        doToggle();
    }, [toggle]);

    const history = useHistory();
   useEffect(() => {
        const unlisten = history.listen(() => {
            toggleBarOnChange();
        });
        return () => {
            unlisten();
        }
    }, []);

    const toggleHandler = (e) => {
        e.preventDefault();
        setToggle(!toggle);
    };
    return (
        <div id="wrapper">
            <nav id="sidebar" className="active">
                <div className="sidebar-header">
                    <h3>PointX</h3>
                    <strong>PX</strong>
                </div>

                <ul className="list-unstyled components">
                    <li>
                        <NavLink to={redirectToPXRoute}>
                            <GoDashboard />
                            {formatMessage({
                                id: "pointx",
                                defaultMessage: "PointX",
                            })}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={pricingPlansRoute}>
                            <IoLayersOutline />
                            {formatMessage({
                                id: "sub_plans",
                                defaultMessage: "Subscription Plan",
                            })}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={transactionsRoute}>
                            <BiTransferAlt />
                            {formatMessage({
                                id: "transactions",
                                defaultMessage: "Transactions",
                            })}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to={editProfileRoute}>
                            <RiUserSettingsLine />
                            {formatMessage({
                                id: "edit_profile",
                                defaultMessage: "Profile",
                            })}
                        </NavLink>
                    </li>{" "}
                    <li>
                        <NavLink exact to={editSettings}>
                            <FiSettings />
                            {formatMessage({
                                id: "settings",
                                defaultMessage: "Settings",
                            })}
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink exact to={editSecurity}>
                            <MdSecurity />
                            {formatMessage({
                                id: "security",
                                defaultMessage: "Security",
                            })}
                        </NavLink>
                    </li> */}
                </ul>

                <div className="text-center d-none w-100 d-md-inline-block px-3">
                    <button
                        className="btn btn-light w-100"
                        onClick={toggleHandler}
                    >
                        {toggle ? (
                            <AiOutlineArrowLeft />
                        ) : (
                            <AiOutlineArrowRight />
                        )}
                    </button>
                    <hr />
                </div>
                <div className="text-center w-100 d-inline-block px-3">
                    <SpinnerButton
                        className="btn btn-outline-light w-100"
                        onClick={() => logout(request)}
                        loading={requesting}
                    >
                        <AiOutlinePoweroff
                            className={toggle ? "mr-md-1" : ""}
                        />
                        {toggle &&
                            formatMessage({
                                id: "logout",
                                defaultMessage: "Logout",
                            })}
                    </SpinnerButton>
                </div>
            </nav>
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Navbar onClick={toggleHandler} multilingual />
                    {children}
                </div>
            </div>
        </div>
    );
};

export default SideBar;
