import vest, {validate, test, enforce} from "vest";

export default function changePasswordValidations(data, field, formatMessage) {
    return validate("ChangePasswordForm", () => {
        vest.only(field);
        ["password", "password_confirm"].forEach((elem) => {
            test(
                elem,
                formatMessage({
                    id: "required_field",
                    defaultMessage: "This field is required",
                }),
                () => {
                    enforce(data[elem].toString()).isNotEmpty();
                }
            );
        });

        test(
            "password",
            formatMessage({
                id: "password_max_8",
                defaultMessage: "Password should be at least 8 characters long",
            }),
            () => {
                enforce(data.password.toString()).longerThanOrEquals(8);
            }
        );

        test(
            "password_confirm",
            formatMessage({
                id: "match_pass_required",
                defaultMessage: "Passwords should be matching",
            }),
            () => {
                enforce(
                    !data.password
                        .toString()
                        .localeCompare(data.password_confirm.toString())
                ).isTruthy();
            }
        );

        test(
            "password",
            formatMessage({
                id: "match_pass_required",
                defaultMessage: "Passwords should be matching",
            }),
            () => {
                if (data.password_confirm && data.password_confirm !== "")
                    enforce(
                        !data.password
                            .toString()
                            .localeCompare(data.password_confirm.toString())
                    ).isTruthy();
            }
        );
    });
}
