import React, {useState} from "react";
import {AddManagerModal, EditManagerModal} from "components/modals";
import {ManagersTable} from "components/tables";

const AdminManagersPage = () => {
    const [manager, setManager] = useState({});
    const [fetch, triggerFetch] = useState({});
    return (
        <>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h6 className="m-0 font-weight-bold text-primary">
                            Managers
                        </h6>
                        <div className="ml-auto">
                            <EditManagerModal
                                triggerFetch={triggerFetch}
                                manager={manager}
                            />
                            <AddManagerModal triggerFetch={triggerFetch} />
                        </div>
                    </div>
                    <div className="card-body">
                        <ManagersTable setManager={setManager} fetch={fetch} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminManagersPage;
