import React from "react";
import {Modal} from "utils";
import {EditClientTransactionForm} from "components/forms";

const EditClientSubModal = ({client}) => {
    return (
        <>
            <Modal
                id="edit-client-subscription"
                header="Edit Client Subscription"
                size="lg"
            >
                <EditClientTransactionForm client={client} />
            </Modal>
        </>
    );
};

export default EditClientSubModal;
