import React, {useEffect, useState} from "react";
import {useRequest, useValidation} from "hooks";
import {adminManagerRequests} from "requests";
import {MultiSelect, SpinnerButton, ValidatedInput} from "utils";
import {managersFormValidations} from "./validations";
import {privileges as _privileges, privilegesList} from "config/privilege";
import $ from "jquery";
import {toast} from "react-toastify";

const defaultManager = {
    username: "",
    password: null,
    privileges: [],
};

const ManagersForm = ({edit, manager: props_manager, triggerFetch}) => {
    const [manager, setManager] = useState(defaultManager);
    const {errors, validate, addErrors} = useValidation(
        managersFormValidations
    );
    const [request, isRequesting] = useRequest(adminManagerRequests);

    useEffect(() => {
        if (props_manager && props_manager.username) {
            let p = [];
            props_manager.privileges.forEach((privilege) => {
                p.push({label: _privileges[privilege], value: privilege});
            });
            setManager({...props_manager, privileges: p});
        }
    }, [props_manager]);
    const onChangeHandler = ({target: {name, value}}) => {
        const newManager = {...manager, [name]: value};
        setManager(newManager);
        validate(newManager, name).catch((e) => {});
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        const type = edit ? "EDIT" : "ADD";
        let privilegesValues = [];
        manager.privileges.forEach((p) => {
            privilegesValues.push(p.value);
        });
        const EDIT = edit && !manager.password;
        validate(manager, EDIT ? "username" : null)
            .then((r) => {
                request({
                    username: manager.username,
                    password: manager.password,
                    privileges: privilegesValues,
                    type,
                })
                    .then((r) => {
                        toast.success("Admin saved successfully");
                        triggerFetch(Math.random());
                        if (edit) $("#edit-manager").modal("hide");
                        else $("#add-manager").modal("hide");
                        $("body").removeClass("modal-open");
                        $(".modal-backdrop").remove();
                    })
                    .catch((e) => {
                        toast.error("Error occured while saving admin");
                        try {
                            addErrors({
                                username:
                                    e.response.data.data.validation.username,
                            });
                        } catch (error) {}
                    });
            })
            .catch((e) => {});
    };
    return (
        <form className="user" onSubmit={onSubmitHandler}>
            <div className="form-group">
                <div className="form-row">
                    <div className="form-col col-12 col-md-6 mb-3 mb-md-0">
                        <ValidatedInput
                            type="text"
                            name="username"
                            className="form-control bg-light"
                            placeholder="Username"
                            label="Username"
                            value={manager.username}
                            error={errors.username}
                            disabled={edit}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="form-col col-12 col-md-6">
                        <ValidatedInput
                            type="password"
                            name="password"
                            className="form-control bg-light"
                            placeholder="Password"
                            label="Password"
                            value={manager.password}
                            error={errors.password}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="form-row">
                    <div className="form-col col-12 mb-3 mb-md-0">
                        <MultiSelect
                            options={privilegesList ? privilegesList : []}
                            onSelect={onChangeHandler}
                            placeholder="Select privileges"
                            label="Select privileges"
                            selectedValues={manager.privileges}
                            error={errors.privileges}
                            name="privileges"
                            displayValue="label"
                        />
                    </div>
                </div>
            </div>
            <SpinnerButton
                className="btn btn-primary btn-block"
                loading={isRequesting}
            >
                Save
            </SpinnerButton>
        </form>
    );
};
export default ManagersForm;
