import React, {useState, useEffect} from "react";
import {useRequest} from "hooks";
import {getTransactionsRequest} from "requests";
import {
    FormattedDate,
    FormattedMessage,
    FormattedNumber,
    useIntl,
} from "react-intl";
import Table from "./Table";
import moment from "moment";

const TransactionsTable = () => {
    const {formatMessage} = useIntl();
    const options = {
        filter: false,
        filterType: "multiselect",
        sortOrder:{
            name:"created_at",
            direction:"desc"
        }
    };
    const columns = [
        {
            label: formatMessage({
                id: "transaction_id",
                defaultMessage: "Transaction ID",
            }),
            name: "id",
            options: {
                filter: false,
                customBodyRenderLite: (row) => (
                    <span className="small-text">{data[row].id}</span>
                ),
            },
        },
        {
            label: formatMessage({
                id: "payment_method",
                defaultMessage: "Payment method",
            }),
            name: "type",
            sortable: true,
            options: {
                customBodyRender: (type) =>
                    type === "STRIPE" ? (
                        <FormattedMessage id="stripe" defaultMessage="Stripe" />
                    ) : (
                        <FormattedMessage id="manual" defaultMessage="Manual" />
                    ),
            },
        },
        {
            label: formatMessage({
                id: "subscription_id",
                defaultMessage: "Plan purchased",
            }),
            name: "plan_title",
            options: {filter: true},
        },
        {
            label: formatMessage({
                id: "subscription_length_days",
                defaultMessage: "Subscription length days",
            }),
            name: "subscription_length_days",
            options: {filter: false},
        },
        {
            label: formatMessage({
                id: "amount_paid",
                defaultMessage: "Amount paid",
            }),
            name: "value",
            sortable: true,
            options: {
                customBodyRenderLite: (row) => (
                    <FormattedNumber
                        value={data[row].value}
                        style="currency"
                        currency={data[row].currency}
                    />
                ),
                filter: false,
            },
        },
        {
            label: formatMessage({id: "paid_at", defaultMessage: "Paid at"}),
            name: "created_at",
            options: {
                customBodyRenderLite: (row) => (
                    <FormattedDate
                        value={data[row].created_at}
                        dateStyle="short"
                        timeStyle="medium"
                    />
                ),
                filter: false,
            },
        },
        {
            label: formatMessage({
                id: "payment_for",
                defaultMessage: "Payment for",
            }),
            name: "payment_for",
            options: {
                customBodyRenderLite: (row) => (
                    <div className="d-flex">
                        {data[row].payment_for === "RENEWAL" ? (
                            <FormattedMessage
                                id="renewal"
                                defaultMessage="Renewal"
                            />
                        ) : (
                            <FormattedMessage
                                id="subscription"
                                defaultMessage="Subscription"
                            />
                        )}
                        {data[row].is_cancelled && (
                            <span className="badge badge-pill badge-danger ml-1">
                                {formatMessage({
                                    id: "cancelled",
                                    defaultMessage: "Cancelled",
                                })}
                            </span>
                        )}
                    </div>
                ),
            },
        },
        {
            label: formatMessage({
                id: "invoice_link",
                defaultMessage: "Invoice download link",
            }),
            name: "invoice_link",
            options: {
                customBodyRenderLite: (row) => (
                    <a
                        className="text-primary"
                        href={data[row].invoice_link}
                        target="_blank"
                    >
                        {formatMessage({
                            id: "link",
                            defaultMessage: "Invoice link",
                        })}
                    </a>
                ),
                filter: false,
                sort: false,
            },
        },
    ];

    const [data, setData] = useState([]);
    const [request, requesting] = useRequest(getTransactionsRequest);

    useEffect(() => {
        request()
            .then(({data}) => {
                const transactions = data.data.transactions;
                let adapterTransactions = [];
                transactions.forEach((transaction) => {
                    const {
                        id = "id",
                        subscription_id = "id",
                        transaction: {
                            // id = "id",
                            amount: {value = 50, currency = "USD"},
                            created_at = "datetime",
                            type = "STRIPE|MANUAL",
                            is_cancelled = false,
                        },
                        payment_for = "RENEWAL|SUBSCRIPTION",
                        subscription_length_days = 30,
                        invoice_link = "/",
                        plan_title = ""
                        // username = "clientUsername",
                    } = transaction;
                    const newTransaction = {
                        id,
                        subscription_id,
                        value,
                        currency,
                        created_at,
                        type,
                        payment_for,
                        subscription_length_days,
                        invoice_link,
                        plan_title,
                        is_cancelled
                    };
                    adapterTransactions.push(newTransaction);
                });
                // adapterTransactions.sort((a, b) =>
                //     moment(a.created_at).diff(b.created_at, "millisecond")
                // );
                setData(adapterTransactions);
            })
            .catch((e) => {});
    }, []);

    return (
        <Table
            data={data}
            columns={columns}
            options={options}
            loading={requesting}
        />
    );
};
export default TransactionsTable;
