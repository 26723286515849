import React from "react";
import {Modal} from "utils";
import {FaPlus} from "react-icons/fa";
import {ManagersForm} from "components/forms";

const AddManagerModal = ({triggerFetch}) => {
    return (
        <>
            <Modal id="add-manager" header="Add Manager" size="lg" multiSelect>
                <>
                    <a
                        data-toggle="modal"
                        data-target="#add-manager"
                        href="/"
                        className="btn btn-primary btn-icon-split d-none d-sm-inline-block p-0"
                        onClick={(e) => e.preventDefault()}
                    >
                        <span className="icon">
                            <FaPlus />
                        </span>
                        <span className="text">Add Manager</span>
                    </a>
                    <a
                        data-toggle="modal"
                        data-target="#add-manager"
                        className="btn-circle btn-primary d-inline-flex d-sm-none"
                        href="/"
                        onClick={(e) => e.preventDefault()}
                    >
                        <FaPlus />
                    </a>
                </>
                <ManagersForm triggerFetch={triggerFetch} />
            </Modal>
        </>
    );
};

export default AddManagerModal;
