import React from "react";
import MUIDataTable from "mui-datatables";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {FormattedMessage} from "react-intl";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});
const getMuiTheme = () =>
    createMuiTheme({
        overrides: {
            MUIDataTable: {
                // root: {
                //     backgroundColor: "#FF0000",
                // },
                root: {
                    // fontSize: "14px",
                    // fontFamily: "inherit",
                    // fontSize: "calc(10px + 2vmin)",
                    // fontWeight: "bold",
                    // color: "#858796",
                    // borderStyle: "solid",
                    // borderWidth: "1px",
                },
                paper: {
                    boxShadow: "none",
                },
            },
            MuiTableCell: {
                root: {
                    borderColor: "rgba(0,0,0,.12) !important",
                    borderStyle: "solid",
                    borderRight: "1px",
                    borderTop: "1px",
                    borderBottom: "0px",
                    borderLeft: "0px",
                    padding: "0px !important",
                    paddingLeft: "16px !important",
                    paddingRight: "16px !important",
                    // display: "flex",
                    // alignItems: "center",
                },
                head: {
                    position: "unset !important",
                    border: "1px 1px 0px 0px solid rgba(0,0,0,.12) !important",
                    fontSize: "15px",
                    fontFamily: "inherit !important",
                    fontWeight: "bolder",
                    color: "#858796",
                },
            },
            MUIDataTableBodyCell: {
                root: {
                    fontSize: "14px",
                    fontFamily: "inherit !important",
                    fontWeight: "400",
                    color: "#858796",
                },
                stackedCommon: {
                    [breakpoints.down("sm")]: {
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    },
                },
            },
            MUIDataTableHeadCell: {
                data: {
                    fontSize: "14px",
                    fontFamily: "inherit !important",
                    fontWeight: "bold",
                    color: "#858796",
                },
            },
            MuiTable: {
                root: {
                    border: "1px solid #dee2e6 !important",
                    borderWidth: "1px",
                },
            },
        },
    });
const Progress = () => {
    return (
        <tbody>
            <tr>
                <td colSpan="100%" className="py-3">
                    <div className="d-flex justify-content-center mt-3 w-100">
                        <div
                            className="spinner-border  text-primary"
                            role="status"
                        ></div>
                    </div>
                </td>
            </tr>
        </tbody>
    );
};

const NoRecords = () => {
    return (
        <tbody>
            <tr>
                <td colSpan="100%" className="py-3">
                    <div className="d-flex justify-content-center mt-3 w-100">
                        <FormattedMessage
                            id="no_records"
                            defaultMessage="Sorry, no matching records found"
                        />
                    </div>
                </td>
            </tr>
        </tbody>
    );
};
const Table = ({
    options = {},
    columns = [],
    data = [],
    loading = true,
    components = {},
}) => {
    const _options = {
        filter: true,
        filterType: "multiselect",
        selectableRows: "none",
        print: false,
        ...options,
    };
    const _components = loading
        ? {TableBody: Progress}
        : data.length
        ? {}
        : {TableBody: NoRecords};
    return (
        <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
                data={data}
                columns={columns}
                options={_options}
                components={{..._components, ...components}}
            />
        </MuiThemeProvider>
    );
};
export default Table;
