import {AuthContext} from "context";
import {useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {clientSubInfo, getClientInfo} from "requests";
import useRequest from "./useRequest";

const useClientSub = () => {
    const [savedClientSub, setClient] = useState({});
    const [request, requesting] = useRequest(clientSubInfo);

    const {auth} = useContext(AuthContext);
    useEffect(() => {
        if (auth.account_type === "CLIENT")
            request()
                .then((r) => {
                    const sub = r.data.data.subscriptions[0];
                    setClient(sub);
                })
                .catch((e) => {
                    // toast.error("Failed to load client");
                });
    }, []);
    return [savedClientSub, requesting];
};

export default useClientSub;
