import React, {useState, useEffect} from "react";
import {useRequest} from "hooks";
import {adminTransactionsRequests} from "requests";
import {FormattedNumber} from "react-intl";
import {FilterTransactionsModel} from "components/modals";
import moment from "moment";
import Table from "./Table";
import {ImCancelCircle} from "react-icons/im";
import {confirm} from "utils";

const AdminTransactionsTable = ({fetch}) => {
    const Actions = (i) => {
        const row = data[i];
        return (
            <div className="d-flex mx-2 py-2">
                {row.is_cancelled ? (
                    "No actions"
                ) : (
                    <button
                        type="button"
                        className="btn btn-outline-danger mr-1"
                        onClick={() => confirmCanel(row)}
                    >
                        <ImCancelCircle />
                    </button>
                )}
            </div>
        );
    };
    const confirmCanel = (row) =>
        confirm({
            message: "Are you sure you want to delete this invoice?",
            onConfirm: () => cancelInvoie(row),
        });
    const cancelInvoie = (row) => {
        request({...row, type: "DELETE"})
            .then(() => {
                fetchData();
            })
            .catch(() => {});
    };
    const columns = [
        {
            label: "Transaction ID",
            name: "transaction_id",
            options: {
                sort: true,
                customBodyRenderLite: (row) => (
                    <div className="small-text">{data[row].transaction_id}</div>
                ),
            },
        },
        {
            label: "Payment method",
            name: "type",
            options: {
                sort: true,
                customBodyRenderLite: (row) => (
                    <div className="py-1">
                        {data[row].type} <small>({data[row].meta})</small>
                    </div>
                ),
            },
        },
        {label: "Username", name: "username"},
        {
            label: "Plan purchased",
            name: "plan_title",
            options: {
                sort: true,
                // customBodyRenderLite: (row) => (
                //     <div className="small-text">
                //         {data[row].subscription_id}
                //     </div>
                // ),
            },
        },
        {
            label: "Payment for",
            name: "payment_for",
            options: {
                sort: true,
                customBodyRenderLite: (row) => (
                    <div className="d-flex">
                        {data[row].payment_for === "RENEWAL"
                            ? "Renewal"
                            : "Subscription"}
                        {data[row].is_cancelled && (
                            <span className="badge badge-pill badge-danger ml-1">
                                Cancelled
                            </span>
                        )}
                    </div>
                ),
            },
        },
        {
            label: "amount paid",
            name: "value",
            options: {
                sort: true,
                customBodyRenderLite: (row) => (
                    <FormattedNumber
                        value={data[row].value}
                        style="currency"
                        currency={data[row].currency}
                    />
                ),
            },
        },
        {
            label: "Paid at",
            name: "created_at",
            options: {
                sort: true,
                customBodyRenderLite: (row) =>
                    moment(data[row].created_at).format(
                        "DD/MM/YYYY [at] hh:mm a"
                    ),
            },
        },
        {
            label: "Invoice download link",
            name: "invoice_link",
            options: {
                sort: false,
                customBodyRenderLite: (row) => (
                    <a
                        href={data[row].invoice_link}
                        className="link text-primary"
                        target="_blank"
                    >
                        link
                    </a>
                ),
            },
        },
        {
            label: "Actions",
            name: "",
            options: {
                sort: false,
                customBodyRenderLite: Actions,
            },
        },
    ];
    const options = {
        filter: false,
        sortOrder:{
            name:"created_at",
            direction:"desc"
        }
    };
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState(data);
    const [request, requesting] = useRequest(adminTransactionsRequests);

    const fetchData = () => {
        request()
            .then(({data}) => {
                const transactions = data.data.transactions;
                let adapterTransactions = [];
                transactions.forEach((transaction, i) => {
                    const {
                        id = "id",
                        subscription_id = "ID",
                        transaction: {
                            id: transaction_id = "b6bea8c5-5bb9-4179-99ba-ecb5893eb348",
                            amount: {value = 90, currency = "EUR"},
                            created_at = "2021-02-09T12:49:07.000000Z",
                            type = "STRIPE",
                            meta = "in_1IIveLJ6pV15DZsLSBZhN0KM",
                            is_cancelled = false,
                        },
                        payment_for = "SUBSCRIPTION",
                        plan_title = " ",
                        subscription_length_days = 90,
                        username = "saraa",
                        invoice_link = "",
                    } = transaction;
                    adapterTransactions.push({
                        id,
                        subscription_id,
                        transaction_id: id,
                        value,
                        currency,
                        created_at,
                        is_cancelled,
                        type,
                        meta,
                        payment_for,
                        subscription_length_days,
                        username,
                        invoice_link,
                        plan_title
                    });
                });
                // adapterTransactions.sort((a, b) =>
                //     moment(a.created_at).diff(b.created_at, "millisecond")
                // );
                setData(adapterTransactions);
                setFilterData(adapterTransactions);
            })
            .catch((e) => {});
    };
    useEffect(fetchData, [fetch]);

    return (
        <>
            <FilterTransactionsModel
                setTransactions={setFilterData}
                transactions={data}
            />
            <Table
                columns={columns}
                data={filterData}
                loading={requesting}
                options={options}
            />
        </>
    );
};
export default AdminTransactionsTable;
