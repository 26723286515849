export default function request(axios, data) {
    const type = data ? data.type : "";
    switch (type) {
        case "ADD":
            return axios.post("/clients/admin/add", data);
        case "NO_PAYMENT":
            return axios.post("/subscription/admin/add", data);
        case "EDIT":
            return axios.post("/clients/admin/edit", data);
        case "SUB":
            return axios.post("/subscription/client/admin", data);
        case "RENEW":
            return axios.post("/billing/manual-pay", data);
        case "CANCEL":
            return axios.post("/subscription/admin/cancel", data);
        case "DELETE":
            return axios.post("/clients/admin/delete", data);
        default:
            // default is get
            return axios.get("/clients/admin", data);
    }
}
