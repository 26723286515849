import React, {useState} from "react";
import {useRequest, useValidation} from "hooks";
import {adminClientRequests} from "requests";
import {countries, SingleSelect, SpinnerButton, ValidatedInput} from "utils";
import {addClientValidations} from "./validations";
import $ from "jquery";
import {toast} from "react-toastify";

const defaultClient = {
    first_name: "",
    last_name: "",
    company: "",
    vat: "",
    email: "",
    username: "",
    phone: "",
    password: "",
    password_confirm: "",
    address_line_1: "",
    address_line_2: "",
    country_code: "",
    postal_code: "",
};
const keys = [
    "first_name",
    "last_name",
    "username",
    "email",
    "phone",
    "password",
    "password_confirm",
    "company",
    "vat",
    "address_line_1",
    "address_line_2",
    "postal_code",
];
const labels = [
    "First name",
    "Last name",
    "Username",
    "Email",
    "Phone",
    "Password",
    "Re-enter Password",
    "Company name",
    "VAT number",
    "Address line 1",
    "Address line 2",
    "Postal code",
];

const AddClientForm = ({triggerFetch}) => {
    const [client, setClient] = useState(defaultClient);
    const {errors, validate, addErrors} = useValidation(addClientValidations);
    const [request, isRequesting] = useRequest(adminClientRequests);

    const onChangeHandler = ({target: {name, value}}) => {
        const newClient = {...client, [name]: value};
        setClient(newClient);
        validate(newClient, name).catch((e) => {});
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        const payload = {
            username: client.username,
            password: client.password,
            email: client.email,
            profile_information: {
                first_name: client.first_name,
                last_name: client.last_name,
                phone: client.phone,
                address: {
                    line_1: client.address_line_1,
                    line_2: client.address_line_2,
                    postal_code: client.postal_code,
                    country_code: client.country_code,
                },
            },
            company_information: {
                company_name: client.company,
                vat_number: client.vat,
            },
        };
        validate(client)
            .then(() => {
                request({...payload, type: "ADD"})
                    .then(() => {
                        toast.success("Client saved successfully");
                        triggerFetch(Math.random());
                        $("#add-client").modal("hide");
                        $("body").removeClass("modal-open");
                        $(".modal-backdrop").remove();
                    })
                    .catch((error) => {
                        if (error.response) {
                            const data = error.response.data.data;
                            const validation = data.validation;
                            if (validation.email)
                                addErrors({
                                    email: validation.email,
                                });
                            if (validation.username)
                                addErrors({
                                    username: validation.username,
                                });
                        } else if (error.request) {
                            toast.error("Error occured while saving client");
                        } else {
                            console.log("Error", error.message);
                        }
                    });
            })
            .catch((e) => {});
    };
    const callback = (country, i) => {
        return (
            <option value={country.value} key={i}>
                {country.name}
            </option>
        );
    };
    return (
        <form className="user" onSubmit={onSubmitHandler}>
            <div className="form-group">
                <div className="form-row">
                    {keys.map((key, i) => (
                        <div
                            className={`form-col col-12 col-md-${
                                i <= 2 ? "4" : "6"
                            } mb-3 mb-md-0`}
                            key={key}
                        >
                            <ValidatedInput
                                type={i === 6 || i === 5 ? "password" : "text"}
                                name={key}
                                className="form-control bg-light"
                                placeholder={labels[i]}
                                label={labels[i]}
                                value={client[key]}
                                error={errors[key]}
                                onChange={onChangeHandler}
                            />
                        </div>
                    ))}
                    <div className="form-col col-12 col-md-6 mb-3 mb-md-0">
                        <SingleSelect
                            options={countries}
                            className="form-control bg-light"
                            placeholder="Select A country"
                            name="country_code"
                            label="Country"
                            placeholder="Country"
                            error={errors.country_code}
                            onChange={onChangeHandler}
                            value={client.country_code}
                            callback={callback}
                        />
                    </div>
                </div>
            </div>
            <SpinnerButton
                className="btn btn-primary btn-block"
                loading={isRequesting}
            >
                Save
            </SpinnerButton>
        </form>
    );
};
export default AddClientForm;
