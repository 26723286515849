import {useRequest, useValidation} from "hooks";
import React, {useState} from "react";
import {resetPasswordRequest} from "requests";
import {Img, SpinnerButton, ValidatedInput} from "utils";
import {changePasswordValidations} from "./validations";
import {useIntl} from "react-intl";
import {useParams} from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import {loginRoute} from "routes/routes";
import {toast} from "react-toastify";

const ResetPasswordForm = () => {
    const [password, setUser] = useState({password: ""});
    const {token} = useParams();
    const {errors, validate} = useValidation(
        changePasswordValidations
    );
    const [request, isRequesting] = useRequest(resetPasswordRequest);
    const {formatMessage} = useIntl();
    const [activeStep, setActiveStep] = useState(0);
    const handleChange = (index) => (e) => setActiveStep(index);

    const onChangeHandler = ({target: {name, value}}) => {
        const newPassword = {...password, [name]: value};
        setUser(newPassword);
        validate(newPassword, name).catch((e) => {});
    };
    const onSubmitHandler = (e) => {
        e.preventDefault();
        const data = {
            challenge: token,
            new_password: password.password,
        };
        validate(password)
            .then((r) => {
                request(data)
                    .then((r) => {
                        setActiveStep(1);
                    })
                    .catch((e) => {
                        toast.error(
                            formatMessage({
                                id: "error_occurred",
                                defaultMessage: "An error occurred!",
                            })
                        );
                    });
            })
            .catch((e) => {});
    };

    return (
        <SwipeableViews index={activeStep} onChangeIndex={handleChange}>
            <div className="mx-2">
                <form onSubmit={onSubmitHandler}>
                    <div className="form-group">
                        <div className="form-row">
                            <div className="form-col col-12">
                                <ValidatedInput
                                    type="password"
                                    name="password"
                                    className="form-control"
                                    placeholder={formatMessage({
                                        id: "password",
                                        defaultMessage: "Password",
                                    })}
                                    label={formatMessage({
                                        id: "password",
                                        defaultMessage: "Password",
                                    })}
                                    value={password.password}
                                    onChange={onChangeHandler}
                                    error={errors.password}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-col col-12">
                                <ValidatedInput
                                    type="password"
                                    name="password_confirm"
                                    className="form-control"
                                    placeholder={formatMessage({
                                        id: "password_confirm",
                                        defaultMessage: "Password confirmation",
                                    })}
                                    label={formatMessage({
                                        id: "password_confirm",
                                        defaultMessage: "Password confirmation",
                                    })}
                                    value={password.password_confirm}
                                    onChange={onChangeHandler}
                                    error={errors.password_confirm}
                                />
                            </div>
                        </div>
                    </div>

                    <SpinnerButton
                        className="btn btn-primary btn-block"
                        loading={isRequesting}
                    >
                        {formatMessage({id: "save", defaultMessage: "Save"})}
                    </SpinnerButton>
                </form>
            </div>
            <div className="mx-2">
                <div className="d-block">
                    <Img src="/password_success.svg" className="img-fluid" />
                </div>

                <h4 className="mt-4">
                    {formatMessage({
                        id: "reset_success_complete",
                        defaultMessage: "Reset successful!",
                    })}
                </h4>
                <br />
                <a href={loginRoute} className="btn btn-info">
                    {formatMessage({
                        id: "go_to_login",
                        defaultMessage: "Go to login.",
                    })}
                </a>
            </div>
        </SwipeableViews>
    );
};

export default ResetPasswordForm;
