export default function request(axios, data) {
    const type = data ? data.type : "";
    switch (type) {
        case "ENABLE":
            return axios.get("/billing/client", data);
        case "DISABLE":
            return axios.get("/billing/client", data);
        default:
            return axios.get("/billing/client", data);
    }
}
