import vest, {validate, test, enforce} from "vest";
import {truncate} from "utils";

export default function registerFormValidations(data, field, formatMessage) {
    return validate("EmailLoginForm", () => {
        vest.only(field);
        [
            "first_name",
            "last_name",
            "email",
            "username",
            "password",
            "password_confirm",
        ].forEach((elem) => {
            test(
                elem,
                formatMessage({
                    id: "required_field",
                    defaultMessage: "This field is required",
                }),
                () => {
                    enforce(data[elem].toString()).isNotEmpty();
                }
            );
        });

        test(
            "username",
            formatMessage({
                id: "username_valid_chars",
                defaultMessage:
                    "Username must only contain lowercase letters, numbers and underscores",
            }),
            () => {
                enforce(data.username.toString())
                    .isNotEmpty()
                    .matches(/^[A-Za-z0-9_]*$/g);
            }
        );
        test(
            "username",
            formatMessage({
                id: "username_max_5",
                defaultMessage: "Username should be at least 5 characters long",
            }),
            () => {
                enforce(data.username.toString()).longerThanOrEquals(5);
            }
        );

        test(
            "username",
            formatMessage({
                id: "username_min_5",
                defaultMessage: "Username should be at most 12 characters long",
            }),
            () => {
                enforce(data.username.length).lessThanOrEquals(12);
            }
        );

        const trimmedEmail = truncate(data.email.toString(), 15);
        test(
            "email",
            trimmedEmail.concat(
                " " +
                    formatMessage({
                        id: "invalid_email",
                        defaultMessage: "email is not valid email address",
                    })
            ),
            () => {
                enforce(data.email.toString())
                    .isNotEmpty()
                    .matches(/[^@]+@[^.]+\..+/g);
            }
        );
        test(
            "password",
            formatMessage({
                id: "password_max_8",
                defaultMessage: "Password should be at least 8 characters long",
            }),
            () => {
                enforce(data.password.toString()).longerThanOrEquals(8);
            }
        );

        test(
            "password_confirm",
            formatMessage({
                id: "match_pass_required",
                defaultMessage: "Passwords should be matching",
            }),
            () => {
                enforce(
                    !data.password
                        .toString()
                        .localeCompare(data.password_confirm.toString())
                ).isTruthy();
            }
        );
        test(
            "password",
            formatMessage({
                id: "match_pass_required",
                defaultMessage: "Passwords should be matching",
            }),
            () => {
                if (data.password_confirm && data.password_confirm !== "")
                    enforce(
                        !data.password
                            .toString()
                            .localeCompare(data.password_confirm.toString())
                    ).isTruthy();
            }
        );
    });
}
