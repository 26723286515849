import {ChangeAddressForm} from "components/forms";
import React from "react";
import {Card} from "utils";
import {useIntl} from "react-intl";

const EditProfilePage = () => {
    const {formatMessage} = useIntl();
    return (
        <>
            <Card header={formatMessage({id: "info", defaultMessage: "Info"})}>
                <ChangeAddressForm />
            </Card>
        </>
    );
};

export default EditProfilePage;
