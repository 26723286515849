import {
    ForgotPasswordPage,
    LoginPage,
    RegisterPage,
    ResetPasswordPage,
    ClientDashboardTabs,
    NotFound,
    TermsAndServiesPage,
    PrivacyPolicyPage,
} from "components/pages";
import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import { Title } from "utils";
import PrivateRoute from "./PrivateRoute";
import {
    loginRoute,
    registerRoute,
    forgotPasswordRoute,
    resetPasswordRoute,
    dashboardRoute,
    root,
    verifiedRoute,
    smartLogin,
    termsRoute,
    privacyPolicyRoute,
} from "./routes";

const UserRoutes = () => {
    return (
        <>
            <Switch>
                <Route exact path={root}>
                    <Redirect to={dashboardRoute} />
                </Route>
                <Route path={forgotPasswordRoute}>
                    <Title title="Account Recovery - PointX CM" />
                    <ForgotPasswordPage />
                </Route>
                <Route path={verifiedRoute}>
                    <Title title="Account verified - PointX CM" />
                    <LoginPage verified />
                </Route>
                <Route
                    path={smartLogin}
                    render={({
                        match: {
                            params: {identifier},
                        },
                    }) => (
                        <>
                            <Title title={`Login|${identifier} - PointX CM`} />
                            <LoginPage />
                        </>
                    )}
                />
                <Route path={loginRoute}>
                    <Title title="Login - PointX CM" />
                    <LoginPage />
                </Route>
                <Route path={registerRoute}>
                    <Title title="Sign Up - PointX CM" />
                    <RegisterPage />
                </Route>
                <Route path={termsRoute}>
                    <Title title="Terms and Conditions - PointX CM" />
                    <TermsAndServiesPage />
                </Route>
                <Route path={privacyPolicyRoute}>
                    <Title title="Privacy Policy - PointX CM" />
                    <PrivacyPolicyPage />
                </Route>
                <Route path={`${resetPasswordRoute}/:token`}>
                    <Title title="Account Recovery - PointX CM" />
                    <ResetPasswordPage />
                </Route>
                <PrivateRoute
                    client
                    path={dashboardRoute}
                    component={ClientDashboardTabs}
                />
                <Route
                    path="*"
                    render={({staticContext}) => {
                        if (staticContext) staticContext.statusCode = 404;
                        return (
                            <>
                                <Title title="404 - PointX CM" />
                                <NotFound />
                            </>
                        );
                    }}
                />
            </Switch>
        </>
    );
};

export default UserRoutes;
