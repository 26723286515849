import {useGoogle, useRequest, useValidation} from "hooks";
import React, {useRef, useState} from "react";
import {Link} from "react-router-dom";
import {registerRequest} from "requests";
import {loginRoute, privacyPolicyRoute, termsRoute} from "routes/routes";
import {
    countries,
    Img,
    SingleSelect,
    SpinnerButton,
    ValidatedInput,
} from "utils";
import {
    registerFormStep1Validations,
    registerFormStep2Validations,
} from "./validations";
import {FormattedMessage, useIntl} from "react-intl";
import SwipeableViews from "react-swipeable-views";
import {toast} from "react-toastify";
import "styles/dashboard.css";
// import ReCAPTCHA from "react-google-recaptcha";
// import Reaptcha from "reaptcha";
import ReCAPTCHA from "react-google-recaptcha";

const RegisterForm = () => {
    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        company: "",
        vat: "",
        email: "",
        username: "",
        phone: "",
        password: "",
        password_confirm: "",
        address_line_1: "",
        address_line_2: "",
        country_code: "",
        postal_code: "",
        terms_and_policy: false,
    });
    const {
        errors: step1_errors,
        validate: validate_step1,
        addErrors: step1_add_errors,
    } = useValidation(registerFormStep1Validations);
    const {errors: step2_errors, validate: validate_step2} = useValidation(
        registerFormStep2Validations
    );
    const recaptchaRef = useRef(null);
    const [request, isRequesting] = useRequest(registerRequest);
    const {formatMessage} = useIntl();
    const [activeStep, setActiveStep] = useState(0);
    const [steKey] = useGoogle();
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const step1_keys = [
        "first_name",
        "last_name",
        "username",
        "email",
        "phone",
        "password",
        "password_confirm",
    ];
    const step1_labels = [
        formatMessage({id: "first_name", defaultMessage: "First name"}),
        formatMessage({id: "last_name", defaultMessage: "Last name"}),
        formatMessage({id: "username", defaultMessage: "Username"}),
        formatMessage({id: "email", defaultMessage: "Email"}),
        formatMessage({id: "phone", defaultMessage: "Phone"}),
        formatMessage({id: "password", defaultMessage: "Password"}),
        formatMessage({
            id: "password_re_enter",
            defaultMessage: "Re-enter Password",
        }),
    ];

    const step2_keys = [
        "company",
        "vat",
        "address_line_1",
        "address_line_2",
        "postal_code",
    ];
    const step2_labels = [
        formatMessage({id: "company_name", defaultMessage: "Company name"}),
        formatMessage({id: "eu_vat", defaultMessage: "EU VAT"}),
        formatMessage({id: "address_line_1", defaultMessage: "Address line 1"}),
        formatMessage({id: "address_line_2", defaultMessage: "Address line 2"}),
        formatMessage({id: "postal_code", defaultMessage: "Postal code"}),
    ];

    const handleChange = (index) => (e) => setActiveStep(index);
    const handleStepOne = () => {
        validate_step1(user)
            .then(() => {
                setActiveStep(activeStep + 1);
            })
            .catch((e) => {
                setActiveStep(activeStep);
            });
    };
    // const handlePrev = () => setActiveStep(activeStep - 1);
    const tabs = 4;

    const onChangeHandler = ({target: {name, value}}) => {
        const newUser = {...user, [name]: value};
        setUser(newUser);
        if (step1_keys.includes(name))
            validate_step1(newUser, name).catch((e) => {});
        if (step2_keys.includes(name))
            validate_step2(newUser, name).catch((e) => {});
    };
    const checkboxHandler = ({target: {name, checked}}) => {
        const newUser = {...user, [name]: checked};
        setUser(newUser);
        validate_step2(newUser, name).catch((e) => {});
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        switch (activeStep) {
            case 0:
                handleStepOne();
                break;
            case 1:
                handleStepTwo();
                break;
            case 2:
                handleStepThree();
                break;

            default:
                break;
        }
    };
    const handleStepTwo = () => {
        validate_step2(user)
            .then((r) => {
                setActiveStep(2);
            })
            .catch((e) => {});
    };
    const submit = (token) => {
        let payload = {
            username: user.username,
            password: user.password,
            email: user.email,
            google_recaptcha_token: token,
            profile_information: {
                first_name: user.first_name,
                last_name: user.last_name,
                phone: user.phone,
                address: {
                    line_1: user.address_line_1,
                    line_2: user.address_line_2,
                    postal_code: user.postal_code,
                    country_code: user.country_code,
                },
            },
            company_information: {
                company_name: user.company,
                vat_number: user.vat,
            },
        };
        request(payload)
            .then((r) => {
                setActiveStep(3);
            })
            .catch((error) => {
                if (error.response) {
                    // if (recaptchaRef.current)
                    recaptchaRef.current.reset();
                    setRecaptchaValue(null);
                    setActiveStep(0);
                    const data = error.response.data.data;
                    const validation = data.validation;
                    if (validation.email)
                        step1_add_errors({
                            email: formatMessage({
                                id: "email_exists",
                                defaultMessage: "The email already exists",
                            }),
                        });
                    if (validation.username)
                        step1_add_errors({
                            username: formatMessage({
                                id: "username_exists",
                                defaultMessage: "The username already exists",
                            }),
                        });
                } else if (error.request) {
                    toast.error(
                        formatMessage({
                            id: "register_error",
                            defaultMessage:
                                "An error occured while registering, Please try again.",
                        })
                    );
                } else {
                    console.log("Error", error.message);
                }
            });
    };
    const handleStepThree = () => {
        submit();
    };

    const callback = (country, i) => {
        return (
            <option value={country.value} key={i}>
                {country.name}
            </option>
        );
    };
    return (
        <>
            <form onSubmit={onSubmitHandler} style={{display: "grid"}}>
                <SwipeableViews
                    index={activeStep}
                    onChangeIndex={handleChange}
                    disabled={true}
                >
                    <div className="mx-2">
                        <div className="form-row input-group input-group-md-down-break justify-content-center">
                            {step1_keys.map((key, i) => (
                                <div
                                    key={key}
                                    className={`w-${
                                        i === 1 || !i ? 50 : 100
                                    } pb-2 pr-${!i ? 1 : 0}`}
                                >
                                    <ValidatedInput
                                        type={
                                            i === 6 || i === 5
                                                ? "password"
                                                : "text"
                                        }
                                        name={key}
                                        className="form-control"
                                        placeholder={step1_labels[i]}
                                        label={step1_labels[i]}
                                        value={user[key]}
                                        onChange={onChangeHandler}
                                        error={step1_errors[key]}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="mx-2">
                        <div className="form-row input-group input-group-md-down-break justify-content-center">
                            {step2_keys.map((key, i) => (
                                <div
                                    key={key}
                                    className={`w-${
                                        i === 1 || !i ? 50 : 100
                                    } pb-2 pr-${!i ? 1 : 0}`}
                                >
                                    <ValidatedInput
                                        type="text"
                                        name={key}
                                        className="form-control"
                                        placeholder={step2_labels[i]}
                                        label={step2_labels[i]}
                                        value={user[key]}
                                        onChange={onChangeHandler}
                                        error={step2_errors[key]}
                                    />
                                </div>
                            ))}
                            <SingleSelect
                                options={countries}
                                placeholder={formatMessage({
                                    id: "select_country",
                                    defaultMessage: "Select A country",
                                })}
                                name="country_code"
                                label={formatMessage({
                                    id: "country",
                                    defaultMessage: "Country",
                                })}
                                error={step2_errors.country_code}
                                onChange={onChangeHandler}
                                value={user.country_code}
                                callback={callback}
                            />
                            <div
                                className="custom-control custom-checkbox my-3 pl-0"
                                style={{width: "100%", marginLeft: "1.5rem"}}
                            >
                                <ValidatedInput
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="terms_and_policy"
                                    name="terms_and_policy"
                                    checked={user.terms_and_policy}
                                    error={step2_errors.terms_and_policy}
                                    onChange={checkboxHandler}
                                >
                                    <label
                                        className="custom-control-label"
                                        htmlFor="terms_and_policy"
                                    >
                                        Agree to{" "}
                                        <Link
                                            to={privacyPolicyRoute}
                                            target="_blank"
                                        >
                                            Privacy Policy{" "}
                                        </Link>
                                        &
                                        <Link to={termsRoute} target="_blank">
                                            {" "}
                                            Terms of Service
                                        </Link>
                                    </label>
                                </ValidatedInput>
                            </div>
                        </div>
                    </div>
                    <div className="mx-2 h-100">
                        <div
                            className="d-flex justify-content-center align-items-center h-100"
                            style={{flexDirection: "column"}}
                        >
                            <FormattedMessage
                                id="capth_label"
                                defaultMessage="Verify you're not a robot"
                            />
                            {steKey && (
                                <ReCAPTCHA
                                    sitekey={steKey}
                                    onChange={submit}
                                    ref={recaptchaRef}
                                />
                            )}
                        </div>
                    </div>
                    <div className="mx-2">
                        <div className="d-block">
                            <Img src="mail_sent.svg" className="img-fluid" />
                        </div>

                        <h4 className="mt-4">
                            <FormattedMessage
                                id="registeration_successful"
                                defaultMessage="Registeration successful!"
                            />
                            <br />
                            <FormattedMessage
                                id="check_your_mail"
                                defaultMessage=" please check your mail."
                            />
                        </h4>
                    </div>
                </SwipeableViews>
                <div className="mx-2">
                    <div className="form-row">
                        {activeStep < tabs - 1 && (
                            <SpinnerButton
                                className="btn btn-primary btn-block mt-3"
                                loading={isRequesting}
                                disabled={activeStep === 2}
                                // onClick={handleNext}
                            >
                                {activeStep < tabs && (
                                    <FormattedMessage
                                        id="register"
                                        defaultMessage="Register"
                                    />
                                )}
                                {activeStep === tabs && (
                                    <FormattedMessage
                                        id="register_confirm"
                                        defaultMessage="Confirm registeration"
                                    />
                                )}
                            </SpinnerButton>
                        )}
                    </div>
                </div>
            </form>
            <hr />
            <div className="form-group text-center">
                <b>
                    <Link to={loginRoute}>
                        {formatMessage({
                            id: "already_member",
                            defaultMessage: "Already have an account ? Login",
                        })}
                    </Link>
                </b>
            </div>
        </>
    );
};
export default RegisterForm;
