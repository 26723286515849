import {useEffect, useState} from "react";
import {stripeKeyRequest} from "requests";
import useRequest from "./useRequest";

const useStripe = () => {
    const [stripe, setStripe] = useState("");
    const [request, requesting] = useRequest(stripeKeyRequest);

    useEffect(() => {
        request()
            .then((r) => {
                const s = r.data.data.key;
                setStripe(s);
            })
            .catch(() => {});
    }, []);
    return [stripe, requesting];
};

export default useStripe;
