import vest, {validate, test, enforce} from "vest";

export default function paypalFormValidations(data, field, formatMessage) {
    return validate("SubscriptionPlanForm", () => {
        vest.only(field);
        ["api_key", "webhook_secret", "public_key"].forEach((elem) => {
            test(elem, "This field is required", () => {
                enforce(data[elem].toString()).isNotEmpty();
            });
        });
    });
}
