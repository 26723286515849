import React, {useState, useEffect, useContext} from "react";
import {LanguageContext, languageOptions} from "lang";
import {FormattedMessage, useIntl} from "react-intl";
import {useClientSub, useRequest} from "hooks";
import {saveTimezoneRequest} from "requests";
import {SingleSelect, SpinnerButton, timezones, Card} from "utils";
import {toast} from "react-toastify";
import "components/navbar/LanguageDropdown.css";
import {ChangeEmailForm, ChangePasswordForm} from "components/forms";

const Timezone = ({id, timezone: tz}) => {
    const {formatMessage} = useIntl();
    const [request, isRequesting] = useRequest(saveTimezoneRequest);
    const [timezone, setTimezone] = useState(tz);
    const onChangeHandler = ({target: {value}}) => {
        setTimezone(value);
    };
    const callback = (item, i) => {
        return (
            <option value={item} key={i}>
                {item}
            </option>
        );
    };
    const onSave = () => {
        request({
            subscription_id: id,
            timezone: timezone,
        })
            .then(() => {
                toast.success(
                    formatMessage({
                        id: "save_timezone_success",
                        defaultMessage: "Saved successfully",
                    })
                );
            })
            .catch(() => {
                toast.error(
                    formatMessage({
                        id: "save_timezone_error",
                        defaultMessage: "Error occurred saving time zone",
                    })
                );
            });
    };
    return (
        <div className="row">
            <div className="col-12 col-md-2 mb-2 mb-md-0 pr-md-0">
                <FormattedMessage
                    id="select_timezone"
                    defaultMessage="Select timezone"
                />
            </div>
            <div className="col-12 col-md-8 mb-2 mb-md-0 px-md-0">
                <SingleSelect
                    className="form-control w-100"
                    placeholder={formatMessage({
                        id: "timezone",
                        defaultMessage: "Timezone",
                    })}
                    value={timezone}
                    onChange={onChangeHandler}
                    options={timezones}
                    callback={callback}
                />
            </div>
            <div className="col-12 col-md-2">
                <SpinnerButton
                    className="btn btn-primary btn-block"
                    loading={isRequesting}
                    onClick={onSave}
                >
                    {formatMessage({id: "save", defaultMessage: "Save"})}
                </SpinnerButton>
            </div>
        </div>
    );
};
const Language = () => {
    const {formatMessage} = useIntl();
    const {userLanguage, userLanguageChange = () => {}} = useContext(
        LanguageContext
    );
    const handleLanguageChange = ({target: {value: id}}) => {
        userLanguageChange(id);
    };
    useEffect(() => {
        let defaultLanguage = window.localStorage.getItem("lang");
        if (!defaultLanguage) {
            defaultLanguage = window.navigator.language.substring(0, 2);
        }
        userLanguageChange(defaultLanguage);
    }, [userLanguageChange]);

    
    const callback = ([id, {lang, country}], i) => {
        return (
            <option value={id} key={i}>
                {lang}
            </option>
        );
    };

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-2 mb-2 mb-md-0 pr-md-0">
                    <FormattedMessage
                        id="select_lang"
                        defaultMessage="Select Language"
                    />
                </div>
                <div className="col-12 col-md-8 mb-2 mb-md-0 px-md-0">
                    <SingleSelect
                        className="form-control w-100"
                        placeholder={formatMessage({
                            id: "timezone",
                            defaultMessage: "Timezone",
                        })}
                        value={userLanguage}
                        onChange={handleLanguageChange}
                        options={Object.entries(languageOptions)}
                        callback={callback}
                    />
                </div>
                <div className="col-12 col-md-2 mb-2 mb-md-0 pr-md-0 d-flex justify-content-center">
                    <img
                        className="img-profile rounded-circle  p-0 mr-2"
                        src={`/flags/${languageOptions[userLanguage].country}.svg`}
                        alt={`${languageOptions[userLanguage].country} Flag`}
                    />
                </div>
            </div>
        </>
    );
};
const ClientSecurityPage = () => {
    const {formatMessage} = useIntl();
    const [{id, timezone}, requesting] = useClientSub();
    return (
        <>
            <Card
                header={formatMessage({
                    id: "general",
                    defaultMessage: "General",
                })}
            >
                {requesting ? (
                    <div className="d-flex justify-content-center">
                        <div
                            className="spinner-border  text-primary"
                            role="status"
                        ></div>
                    </div>
                ) : (
                    <Timezone id={id} timezone={timezone} />
                )}
                <hr />
                <Language />
            </Card>
            <hr />
            <Card
                header={formatMessage({
                    id: "security",
                    defaultMessage: "Security",
                })}
            >
                <div className="row">
                    <div className="col-12 col-md-6 border-right">
                        <ChangePasswordForm />
                    </div>
                    <div className="col-12 col-md-6">
                        <ChangeEmailForm />
                    </div>
                </div>
            </Card>
        </>
    );
};

export default ClientSecurityPage;
