import React, {useState} from "react";
import {
    EditClientModal,
    AddClientModal,
    EditClientSubModal,
} from "components/modals";
import {RegisteredClientsTable} from "components/tables";
import {usePrivilege} from "hooks";
import {ACCESS_POINTX, DELETE_CLIENTS, EDIT_CLIENTS} from "config/privilege";

const AdminSubscriptionPlans = () => {
    const [client, setClient] = useState({});
    const [fetch, triggerFetch] = useState({});
    const {canAccess} = usePrivilege();

    return (
        <>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h6 className="m-0 font-weight-bold text-primary">
                            Registered Clients
                        </h6>
                        <div className="ml-auto">
                            {canAccess(EDIT_CLIENTS) && (
                                <EditClientModal
                                    triggerFetch={triggerFetch}
                                    client={client}
                                />
                            )}
                            <AddClientModal triggerFetch={triggerFetch} />
                            <EditClientSubModal client={client} />
                        </div>
                    </div>
                    <div className="card-body">
                        <RegisteredClientsTable
                            setClient={setClient}
                            fetch={fetch}
                            canEdit={canAccess(EDIT_CLIENTS)}
                            canDelete={canAccess(DELETE_CLIENTS)}
                            canAccess={canAccess(ACCESS_POINTX)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminSubscriptionPlans;
