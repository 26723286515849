import vest, {validate, test, enforce} from "vest";

export default function transactionFormValidations(data, field, formatMessage) {
    return validate("SubscriptionPlanForm", () => {
        vest.only(field);
        [
            "subscription_length_days",
            "client_username",
            "plan_id",
            // "amount_paid",
            // "amount_currency",
            // "paid_at",
            // "invoice",
        ].forEach((elem) => {
            test(elem, "This field is required", () => {
                enforce(data[elem].toString()).isNotEmpty();
            });
        });
        // ["amount_paid"].forEach((elem) => {
        //     test(elem, "This should be a number", () => {
        //         enforce(data[elem]).matches(/^[0-9]*.{1}[0-9]*$/g);
        //     });
        // });
    });
}
