import React, {useEffect, useState} from "react";
import {useRequest, useValidation} from "hooks";
// import {clientSubscriptionsRequest as getPlans} from "requests";
import {DateInput, MultiSelect, SpinnerButton, ValidatedInput} from "utils";
import {filterTransFormValidations} from "./validations";
// import Select from "react-select";
import moment from "moment";

const defaultFilter = {
    from: "",
    to: "",
    payment_method: [],
    username: "",
    plan: [],
};

const FilterTransactionsForm = ({setTransactions, transactions = []}) => {
    const [filter, setFilter] = useState(defaultFilter);
    const [selectedValues, setSelected] = useState(defaultFilter);
    // const [plans, setPlans] = useState([]);
    const {errors, validate} = useValidation(filterTransFormValidations);
    // const [request, isRequesting] = useRequest(adminTransactionsRequests);
    // const [planRequest, isRequestingPlans] = useRequest(getPlans);

    // useEffect(() => {
    //     planRequest()
    //         .then(({data}) => {
    //             const p = data.data.plans;
    //             let planList = [];
    //             p.forEach((plan) => {
    //                 planList.push({title: plan.title, value: plan.plan_id});
    //             });

    //             setPlans(planList);
    //         })
    //         .catch((e) => {});
    // }, []);

    const payment_methods = [
        {name: "Stripe", value: "STRIPE"},
        {name: "Manual", value: "MANUAL"},
    ];

    const onChangeHandler = ({target: {name, value}}) => {
        const newFilter = {...filter, [name]: value};
        setFilter(newFilter);
        validate(newFilter, name).catch((e) => {});
        if (name === "to") validate(newFilter, "from").catch((e) => {});
        if (name === "from") validate(newFilter, "to").catch((e) => {});
    };
    const onSelectHandler = ({target: {name, value}}) => {
        const newFilter = {...filter, [name]: value};
        let values = [];
        value.forEach((v) => values.push(v.value));
        const newValues = {...selectedValues, [name]: values};
        setSelected(newValues);
        setFilter(newFilter);
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        const type = "FILTER";
        validate({...filter, type})
            .then((r) => {
                // const {
                //     id = "1a49d3da-263b-41a7-b9ba-8c55dde3b0fa",
                //     subscription_id = "47f07506-23d2-4326-a6df-ce44bd72acc0",
                //     transaction: {
                //         id: transaction_id = "b6bea8c5-5bb9-4179-99ba-ecb5893eb348",
                //         amount: {value = 90, currency = "EUR"},
                //         created_at = "2021-02-09T12:49:07.000000Z",
                //         type = "STRIPE",
                //         meta = "in_1IIveLJ6pV15DZsLSBZhN0KM",
                //     },
                //     payment_for = "SUBSCRIPTION",
                //     subscription_length_days = 90,
                //     username = "saraa",
                // } = transaction;
                try {
                    let filtered = transactions.filter((t) => {
                        const before = moment(filter.to).isAfter(t.created_at);
                        const after = moment(t.created_at).isAfter(filter.from);
                        const username =
                            filter.username.length > 0 &&
                            t.username.includes(filter.username);
                        const method = selectedValues.payment_method.includes(
                            t.type
                        );
                        // console.log(before, after, username, method);
                        return before || after || username || method;
                    });
                    setTransactions(filtered);
                } catch (error) {
                    console.log("error!");
                }
            })
            .catch((e) => {});
    };
    return (
        <form className="user" onSubmit={onSubmitHandler}>
            <div className="form-group">
                <div className="form-row">
                    <div className="form-col col-12 col-md-4 mb-3 mb-md-0">
                        <DateInput
                            type="text"
                            name="from"
                            className="form-control bg-light"
                            placeholder="From"
                            label="From"
                            value={filter.from}
                            error={errors.from}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="form-col col-12 col-md-4 mb-3 mb-md-0">
                        <DateInput
                            type="text"
                            name="to"
                            className="form-control bg-light"
                            placeholder="To"
                            label="To"
                            value={filter.to}
                            error={errors.to}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="form-col col-12 col-md-4">
                        <MultiSelect
                            options={payment_methods}
                            onSelect={onSelectHandler}
                            placeholder="Select payment method"
                            label="Select payment method"
                            selectedValues={filter.payment_method}
                            name="payment_method"
                            displayValue="name"
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="form-row">
                    <div className="form-col col-12 col-md-4 mb-3 mb-md-0">
                        <ValidatedInput
                            type="text"
                            name="username"
                            className="form-control bg-light"
                            placeholder="Username"
                            label="Username"
                            value={filter.username}
                            onChange={onChangeHandler}
                        />
                    </div>
                    {/* <div className="form-col col-12 col-md-4 mb-3 mb-md-0">
                        <MultiSelect
                            options={plans}
                            onSelect={onSelectHandler}
                            placeholder="Select plan"
                            label="Select plan"
                            selectedValues={filter.plan}
                            name="plan"
                            displayValue="title"
                        />
                    </div> */}
                    <div className="form-col col-12 col-md-4">
                        <div
                            style={{
                                display: "flex",
                                alignItems: "flex-end",
                                height: "100%",
                            }}
                        >
                            <SpinnerButton
                                className="btn btn-outline-primary btn-block"
                                data-toggle="modal"
                                data-target="#filter-transaction"
                                type="button"
                                onClick={(e) => setTransactions(transactions)}
                            >
                                Reset
                            </SpinnerButton>
                        </div>
                    </div>
                    <div className="form-col col-12 col-md-4">
                        <div
                            style={{
                                display: "flex",
                                alignItems: "flex-end",
                                height: "100%",
                            }}
                        >
                            <SpinnerButton
                                className="btn btn-primary btn-block"
                                data-toggle="modal"
                                data-target="#filter-transaction"
                            >
                                Filter
                            </SpinnerButton>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};
export default FilterTransactionsForm;
