import {useRequest, useValidation} from "hooks";
import React, {useContext, useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {loginRequest} from "requests";
import {forgotPasswordRoute, registerRoute} from "routes/routes";
import {SpinnerButton, ValidatedInput} from "utils";
import {loginFormValidations} from "./validations";
import {useIntl} from "react-intl";
import {AuthContext} from "context";

const LoginForm = ({verified}) => {
    const [user, setUser] = useState({email: "", password: ""});
    const {errors, validate, addErrors} = useValidation(loginFormValidations);
    const [request, isRequesting] = useRequest(loginRequest);
    const {setAuth} = useContext(AuthContext);
    const {formatMessage} = useIntl();
    const history = useHistory();
    const {identifier} = useParams();

    useEffect(() => {
        if (identifier) setUser({...user, email: identifier});
    }, [identifier]);

    const onChangeHandler = ({target: {name, value}}) => {
        const newUser = {...user, [name]: value};
        setUser(newUser);
        validate(newUser, name).catch((e) => {});
    };
    const checkboxHandler = ({target: {name, checked}}) => {
        const newUser = {...user, [name]: checked};
        setUser(newUser);
    };
    const onSubmitHandler = (e) => {
        e.preventDefault();
        const data = {
            identifier: user.email,
            password: user.password,
            remember_token: user.remember_token,
        };
        validate(user)
            .then((r) => {
                request(data)
                    .then(({data}) => {
                        setAuth({
                            access_token: data.data.access_token,
                            refresh_token: data.data.remember_token,
                            is_logged_in: true,
                            account_type: "CLIENT",
                        });
                        history.push("/dashboard");
                    })
                    .catch((e) => {
                        console.log("error");
                        addErrors({
                            email: ["Wrong username/password"],
                            password: ["Wrong username/password"],
                        });
                    });
            })
            .catch((e) => {});
    };
    return (
        <>
            <form onSubmit={onSubmitHandler}>
                {verified && (
                    <div className="alert alert-success mt-2" role="alert">
                        {formatMessage({
                            id: "account_verified",
                            defaultMessage:
                                "Your account has been verified, login now!",
                        })}
                    </div>
                )}
                <div className="form-group">
                    <ValidatedInput
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder={formatMessage({
                            id: "email_or_username",
                            defaultMessage: "Enter email or username",
                        })}
                        label={formatMessage({
                            id: "email_or_username",
                            defaultMessage: "Enter email or username",
                        })}
                        value={user.email}
                        onChange={onChangeHandler}
                        error={errors.email}
                    />
                </div>
                <div className="form-group">
                    <ValidatedInput
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder={formatMessage({
                            id: "password",
                            defaultMessage: "Password",
                        })}
                        label={formatMessage({
                            id: "password",
                            defaultMessage: "Password",
                        })}
                        value={user.password}
                        error={errors.password}
                        onChange={onChangeHandler}
                    />
                </div>
                <div
                    className="custom-control custom-checkbox my-3 pl-0"
                    style={{width: "100%", marginLeft: "1.5rem"}}
                >
                    <ValidatedInput
                        type="checkbox"
                        className="custom-control-input"
                        id="remember_token"
                        name="remember_token"
                        checked={user.remember_token}
                        onChange={checkboxHandler}
                    >
                        <label
                            className="custom-control-label"
                            htmlFor="remember_token"
                        >
                            {formatMessage({
                                id: "remember_me",
                                defaultMessage: "Remember me?",
                            })}
                        </label>
                    </ValidatedInput>
                </div>
                <SpinnerButton
                    className="btn btn-primary btn-sm btn-block"
                    loading={isRequesting}
                >
                    {formatMessage({id: "login", defaultMessage: "Login"})}
                </SpinnerButton>
            </form>
            <hr />
            <div className="form-group text-center">
                <Link to={forgotPasswordRoute}>
                    {formatMessage({
                        id: "forgot_password",
                        defaultMessage: "Forgot password?",
                    })}
                </Link>
            </div>
            <div className="form-group text-center">
                <b>
                    <Link to={registerRoute}>
                        {formatMessage({
                            id: "not_a_member_signup",
                            defaultMessage: "not a member? Signup",
                        })}
                    </Link>
                </b>
            </div>
        </>
    );
};
export default LoginForm;
