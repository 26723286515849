import React from "react";
import {Img} from "utils";
import "styles/dashboard.css";

const AdminDashboardPage = () => {
    return (
        <>
            <div className="d-flex justify-content-center align-items-center">
                {/* <div style={{position: "absolute", width: "100%"}}></div> */}
                {/* <div className="col-12 col-md-6"> */}
                <div className="d-flex flex-column h-100 pb-4">
                    <div className="img-container">
                        <Img src="/control_panel.svg" className="img-fluid" />
                        <div
                            className="m-3 mb-4"
                            style={{
                                position: "absolute",
                                zIndex: 1,
                                display: "flex",
                                justifyContent: "center",
                                background: "#4e73df",
                                width: "80%",
                                paddingTop: 10,
                                borderRadius: "10px",
                                border: "2px solid #eee",
                                // boxShadow: "15px 5px 5px 0px",
                            }}
                        >
                            <h3 style={{color: "white"}}>Welcome!!</h3>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </>
    );
};

export default AdminDashboardPage;
