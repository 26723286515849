import vest, {validate, test, enforce} from "vest";
import {truncate} from "utils";

export default function changeEmailValidations(data, field, formatMessage) {
    return validate("ChangeEmailForm", () => {
        vest.only(field);
        test(
            "email",
            formatMessage({
                id: "required_field",
                defaultMessage: "This field is required",
            }),
            () => {
                enforce(data.email.toString()).isNotEmpty();
            }
        );

        const trimmedEmail = truncate(data.email.toString(), 15);
        test(
            "email",
            trimmedEmail.concat(
                " " +
                    formatMessage({
                        id: "invalid_email",
                        defaultMessage: "email is not valid email address",
                    })
            ),
            () => {
                enforce(data.email.toString())
                    .isNotEmpty()
                    .matches(/[^@]+@[^.]+\..+/g);
            }
        );
    });
}
