import React from "react";
import {Modal} from "utils";
import {ManagersForm} from "components/forms";

const EditManagerModal = ({manager, triggerFetch}) => {
    return (
        <>
            <Modal
                id="edit-manager"
                header="Edit Manager"
                size="md"
                multiSelect
            >
                <div className="container-fluid pb-3">
                    <ManagersForm
                        edit
                        manager={manager}
                        triggerFetch={triggerFetch}
                    />
                </div>
            </Modal>
        </>
    );
};

export default EditManagerModal;
